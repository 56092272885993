const carouselIconData = [
  {
    alt: 'three people looking at chart',
    maxWidth: '13.75em'
  },
  {
    alt: 'two people talking to each other',
    maxWidth: '17.361em'
  },
  {
    alt: 'three hands in the air',
    maxWidth: '13.339em'
  },
  {
    alt: 'a brain through a magnifying glass',
    maxWidth: '13.739em'
  },
  {
    alt: 'four people talking to each other',
    maxWidth: '13.143em'
  },
  {
    alt: 'two jigsaw pieces fitting together',
    maxWidth: '15.669em'
  },
  {
    alt: "cogs turning over three peoples' heads",
    maxWidth: '16.470em'
  },
  {
    alt: 'brain in a lightbulb',
    maxWidth: '13.842em'
  },
  {
    alt: 'lightning bolt in a brain',
    maxWidth: '13.75em'
  },
  {
    alt: 'three people surrounded by stars',
    maxWidth: '17.726em'
  }
];

export default carouselIconData;
