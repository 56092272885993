import React from 'react';
import styled, { css } from 'styled-components';
import crossmark from 'images/Shared/crossmark.svg';

const getPlace = (i) => {
  switch (i) {
    case 1:
      return '1st';
    case 2:
      return '2nd';
    case 3:
      return '3rd';
    case 4:
      return '4th';
    case 5:
      return '5th';
    case 6:
      return '6th';
    case 7:
      return '7th';
    case 8:
      return '8th';
    case 9:
      return '9th';
    case 10:
      return '10th';
    default:
      break;
  }
};

export default ({ game, toggleScoreboard }) => (
  <Wrapper>
    <CloseModal onClick={() => toggleScoreboard()}>
      <img src={crossmark} alt="crossmark" />
    </CloseModal>
    <Scoreboard>Scoreboard</Scoreboard>
    <Stats>{`Week ${game.weekInProgress}, Level ${game.highestActiveLevel} In Progress`}</Stats>
    <TableHead>
      <div>Place</div>
      <Seperator />
      <div>Team Name</div>
      <Seperator />
      <div>Challenge Points</div>
      <Seperator />
      <div>Game Points</div>
      <div>Total</div>
    </TableHead>
    {game.teams
      .map((team) => {
        if (team.points) {
          Object.values(team.points).reduce((totalLevelPoints, level) => {
            if (level) {
              level.totalLevelPoints = totalLevelPoints + level.challengePoints + level.gamePoints;
            }
            return totalLevelPoints;
          }, 0);
        }
        return team;
      })
      .sort((a, b) => {
        b.totalPoints =
          b.points &&
          Object.values(b.points).reduce((totalPoints, level) => {
            return totalPoints + (level ? level.totalLevelPoints : 0);
          }, 0);
        a.totalPoints =
          a.points &&
          Object.values(a.points).reduce((totalPoints, level) => {
            return totalPoints + (level ? level.totalLevelPoints : 0);
          }, 0);
        return b.totalPoints - a.totalPoints;
      })
      .map((team, index) => {
        const { name, totalPoints, points } = team;
        return (
          <Entry index={index} key={team.name}>
            <Place>{getPlace(index + 1)}</Place>
            <Seperator />
            <TeamName>{name}</TeamName>
            <Seperator />
            <ChallengePoints>
              {points &&
                Object.values(points).reduce(
                  (totalChallengePoints, level) =>
                    totalChallengePoints + (level ? level.challengePoints : 0),
                  0
                )}
            </ChallengePoints>
            <Seperator />
            <GamePoints>
              {points &&
                Object.values(points).reduce(
                  (totalGamePoints, level) => totalGamePoints + (level ? level.gamePoints : 0),
                  0
                )}
            </GamePoints>
            <Seperator />
            <TotalPoints>{totalPoints}</TotalPoints>
          </Entry>
        );
      })}
  </Wrapper>
);

const Wrapper = styled.section`
  max-width: 56.25em;
  overflow-y: auto;
  width: 100%;
`;

const Scoreboard = styled.h3`
  font-size: 2rem;
  margin-top: 0.3em;
  text-align: center;
`;

const Stats = styled.p`
  font-size: 1rem;
  margin: 0.55em auto 1.85em;
  text-align: center;
`;

const TableHead = styled.div`
  align-items: center;
  background-color: rgba(239, 237, 228, 0.3);
  display: flex;
  height: 3rem;
  margin-bottom: 0.625em;

  > div:nth-child(odd),
  > div:last-child {
    align-items: center;
    color: var(--cx-dark-green);
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase;
  }

  > div:nth-child(1) {
    flex-basis: 5.688em;
  }

  > div:nth-child(3) {
    flex-basis: 14.125em;
  }

  > div:nth-child(5) {
    flex-basis: 13.75em;
  }

  > div:nth-child(7) {
    flex-basis: 13.438em;
  }

  > div:nth-child(8) {
    background-color: var(--cx-dark-green);
    color: #fff;
    flex-basis: 9.438em;
    height: 100%;
  }
`;

const Entry = styled.div`
  align-items: center;
  background-color: ${({ index }) =>
    index % 2 === 0 ? 'rgba(239, 237, 228, 0.5)' : css`var(--cx-beige)`};
  display: flex;
  height: 3rem;
  margin-bottom: 0.125rem;

  > div {
    &:nth-child(odd) {
      align-items: center;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 1rem;
      justify-content: center;
    }

    &:not(:nth-child(3)) {
      font-weight: 700;
    }

    /* &:nth-child(3) {
      cursor: pointer;
      text-decoration: underline;
    } */
  }
`;

const Place = styled.div`
  flex-basis: 5.688em;
`;

const TeamName = styled.div`
  flex-basis: 14.125em;
`;

const ChallengePoints = styled.div`
  flex-basis: 13.75em;
`;

const GamePoints = styled.div`
  flex-basis: 13.438em;
`;

const TotalPoints = styled.div`
  flex-basis: 9.313em;
`;

const Seperator = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  height: 2.625rem;
  width: 0.125rem;
`;

const CloseModal = styled.button`
  background-color: var(--cx-dark-green);
  border: none;
  cursor: pointer;
  height: 2.875rem;
  outline-color: var(--cx-dark-green);
  position: absolute;
  right: 0;
  top: 0;
  width: 2.875rem;
`;
