import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --cx-beige: #efede4;
    --cx-dark-beige: #c1beac;
    --cx-hover-beige: #dcd7bc;
    --cx-light-green: #8cc63f;
    --cx-dark-green: #009245;
  }

  html {
    box-sizing: border-box;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  /* Scrollbar styling if viewport is over 1460px */
  ${
    '' /* body::-webkit-scrollbar {
    width: 0.625em;
  }
  body::-webkit-scrollbar-track {
    background-color: ${promozooBlack};
  }
  body::-webkit-scrollbar-thumb {
    background-color: ${promozooRed};
    border-radius: 5px;
  } */
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  body {
    color: #000;
    font-family: proxima-nova, sans-serif;
  }
`;
