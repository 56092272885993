import * as React from 'react';

function SvgGreenRightArrow(props) {
  return (
    <svg viewBox="0 0 42 20" fill="none" {...props}>
      <path d="M31.429 11.954H0V8.046h31.429L33.138 10l-1.71 1.954z" fill="#009245" />
      <path d="M33.138 0h-4.99l7.868 9.99L28.148 20h4.99l7.867-10.01L33.139 0z" fill="#009245" />
    </svg>
  );
}

export default SvgGreenRightArrow;
