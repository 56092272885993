import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import XLogo from 'images/Home/XLogo';
import campaignXLogo from 'images/Shared/campaign_x_logo.svg';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import GreenDownArrow from 'images/Shared/GreenDownArrow';
import lock from 'images/Shared/Header/lock.svg';
import hamburger from 'images/Shared/Header/hamburger.svg';
import cross from 'images/Shared/Header/cross.svg';
import facebookIconBlack from 'images/Shared/Header/facebook_icon_black.svg';
import linkedInIconBlack from 'images/Shared/Header/linkedin_icon_black.svg';
import twitterIconBlack from 'images/Shared/Header/twitter_icon_black.svg';
import instagramIconBlack from 'images/Shared/Header/instagram_icon_black.svg';

class Header extends PureComponent {
  state = {
    isHamburgerMenuIsOpen: false,
    makeVerticalDividerBackgroundDarkBeige: false
  };

  handleLevelSelection = (e, isDemoGame) => {
    e.stopPropagation();
    let { level } = e.target.dataset;
    if (level) {
      level = parseInt(level.charAt(0) === '0' ? level.slice(1) : level, 10);
      this.props.updateLevel(level);
      navigate(isDemoGame ? `/level-${level}-demo/?game=demo` : `/level-${level}/`);
    }
  };

  handleVerticalDividerBackgroundColor = ({ makeVerticalDividerBackgroundDarkBeige }) => {
    this.setState({
      makeVerticalDividerBackgroundDarkBeige
    });
  };

  toggleHamburgerMenu = () => {
    this.setState((prevState) => ({
      isHamburgerMenuIsOpen: !prevState.isHamburgerMenuIsOpen
    }));
  };

  handleHamburgerPageLinkClick = (e, path) => {
    this.toggleHamburgerMenu();
    if (path !== 'mailto:hello@campaign-x.com') {
      e.preventDefault();
      setTimeout(() => {
        navigate(path);
      }, 400);
    }
  };

  render() {
    const {
      companyLogo,
      game,
      highestActiveLevel,
      isDemoGame,
      isWelcomeVideoVisible,
      netlifyIdentity,
      selectedLevel,
      siteVisitorNotAuthorisedToPlay,
      toggleMultipleGamesModal,
      toggleScoreboard,
      updateLevel,
      user,
      usersMultipleGameData,
      viewportWidth
    } = this.props;

    const { makeVerticalDividerBackgroundDarkBeige, isHamburgerMenuIsOpen } = this.state;
    return viewportWidth < 768 ? (
      <>
        <HamburgerMenu isHamburgerMenuIsOpen={isHamburgerMenuIsOpen}>
          <Link
            onClick={(e) => this.handleHamburgerPageLinkClick(e, isDemoGame ? '/?game=demo' : '/')}
            to={isDemoGame ? '/?game=demo' : '/'}>
            Home
          </Link>
          <Link
            onClick={(e) =>
              this.handleHamburgerPageLinkClick(e, isDemoGame ? '/rules/?game=demo' : '/rules/')
            }
            to={isDemoGame ? '/rules/?game=demo' : '/rules/'}>
            Rules
          </Link>
          <a
            onClick={(e) => this.handleHamburgerPageLinkClick(e, 'mailto:hello@campaign-x.com')}
            href="mailto:hello@campaign-x.com"
            target="__blank">
            Contact Us
          </a>
          <SocialMedia>
            <h4>Find Us On</h4>
            <a href="https://www.facebook.com/AgencyXdublin/">
              <img src={facebookIconBlack} alt="facebook logo" />
            </a>
            <a href="https://www.linkedin.com/company/agencyx-dublin/">
              <img src={linkedInIconBlack} alt="linkedin logo" />
            </a>
            <a href="https://twitter.com/AgencyX_Dublin/">
              <img src={twitterIconBlack} alt="twitter logo" />
            </a>
            <a href="https://www.instagram.com/agency_x_dublin/">
              <img src={instagramIconBlack} alt="instagram logo" />
            </a>
          </SocialMedia>
        </HamburgerMenu>
        <MobileHeader>
          {getLogo(updateLevel, isDemoGame)}
          <HamburgerMenuToggle onClick={() => this.toggleHamburgerMenu()}>
            {isHamburgerMenuIsOpen ? (
              <img src={cross} alt="cross" />
            ) : (
              <img src={hamburger} alt="hamburger menu" />
            )}
          </HamburgerMenuToggle>
        </MobileHeader>
      </>
    ) : (
      <TabletHeaderWrapper showCompanyLogo={companyLogo}>
        <TabletHeader>
          {getLogo(updateLevel, isDemoGame)}
          <Nav>
            <Link to={isDemoGame ? '/?game=demo' : '/'} onClick={() => updateLevel(null)}>
              <Home>Home</Home>
            </Link>
            <Levels>
              <DropDownMenu
                onClick={(e) => this.handleLevelSelection(e, isDemoGame)}
                showSelectedLevel={selectedLevel}
                showCompanyLogo={user}>
                {getLevels(user, highestActiveLevel, selectedLevel, isDemoGame)}
              </DropDownMenu>
              <span>
                Levels
                <StyledGreenDownArrow />
              </span>
            </Levels>
            <Link
              to={isDemoGame ? '/rules/?game=demo' : '/rules/'}
              onMouseEnter={() =>
                this.handleVerticalDividerBackgroundColor({
                  makeVerticalDividerBackgroundDarkBeige: true
                })
              }
              onMouseOut={() =>
                this.handleVerticalDividerBackgroundColor({
                  makeVerticalDividerBackgroundDarkBeige: false
                })
              }
              onBlur={() =>
                this.handleVerticalDividerBackgroundColor({
                  makeVerticalDividerBackgroundDarkBeige: false
                })
              }>
              <Rules onClick={() => updateLevel(null)}>Rules</Rules>
            </Link>
            {!siteVisitorNotAuthorisedToPlay && (
              <>
                {(user || !isDemoGame) && (
                  <VerticalDivider
                    makeVerticalDividerBackgroundDarkBeige={makeVerticalDividerBackgroundDarkBeige}>
                    <span />
                  </VerticalDivider>
                )}
                <LogInAndOut
                  isDemoGame={isDemoGame}
                  user={user}
                  onClick={() =>
                    isWelcomeVideoVisible || isDemoGame
                      ? netlifyIdentity.open('signup')
                      : netlifyIdentity.open()
                  }
                  onMouseEnter={() =>
                    this.handleVerticalDividerBackgroundColor({
                      makeVerticalDividerBackgroundDarkBeige: true
                    })
                  }
                  onMouseOut={() =>
                    this.handleVerticalDividerBackgroundColor({
                      makeVerticalDividerBackgroundDarkBeige: false
                    })
                  }
                  onBlur={() =>
                    this.handleVerticalDividerBackgroundColor({
                      makeVerticalDividerBackgroundDarkBeige: false
                    })
                  }>
                  {user
                    ? user.name.includes(' ')
                      ? user.name
                          .split(' ')
                          .slice(0, user.name.split(' ').length - 1)
                          .join(' ')
                      : user.name
                    : isWelcomeVideoVisible || isDemoGame
                    ? 'Sign Up'
                    : 'Log In'}
                </LogInAndOut>
              </>
            )}
            {user && game && game.teams.length > 1 && highestActiveLevel > 1 && (
              <Scoreboard onClick={toggleScoreboard}>Scoreboard</Scoreboard>
            )}
          </Nav>
        </TabletHeader>
        {companyLogo && (
          <CompanyLogoWrapper
            onClick={() => {
              if (usersMultipleGameData) {
                toggleMultipleGamesModal();
              }
            }}
            usersMultipleGameData={usersMultipleGameData}
            role="button"
            tabIndex={0}>
            <CompanyLogo
              fixed={companyLogo.childImageSharp.fixed}
              alt={companyLogo.name.replace('_', ' ')}
            />
          </CompanyLogoWrapper>
        )}
      </TabletHeaderWrapper>
    );
  }
}

const getLogo = (updateLevel, isDemoGame) => (
  <Logo>
    <Link to={isDemoGame ? '/?game=demo' : '/'} onClick={() => updateLevel(null)}>
      <CampaignXLogo src={campaignXLogo} alt="Campaign X logo" />
    </Link>
  </Logo>
);

const getLevels = (user, highestActiveLevel, selectedLevel, isDemoGame) => {
  const levels = [];

  for (let i = 1; i <= 10; i++) {
    levels.push(
      <Link
        to={isDemoGame && i === 1 ? `/level-${i}-demo/?game=demo` : `/level-${i}/`}
        key={`/level-${i}/`}
        onClick={(e) => e.preventDefault()}>
        <LevelButton
          locked={!user || i > highestActiveLevel}
          data-level={`0${i}`}
          isDemoGame={isDemoGame && i === 1}
          selected={selectedLevel === i}>
          {i < 10 ? (
            <p>
              {isDemoGame && i === 1 ? 'DEMO' : `0${i}`}
              <span>Level</span>
            </p>
          ) : (
            <>
              <StyledXLogo alt="campaign x logo" selected={selectedLevel === 10} />
              <span
                style={{
                  position: 'relative',
                  top: '0.4em'
                }}>
                Level
              </span>
            </>
          )}
          {(!user || i > highestActiveLevel) && <Lock src={lock} />}
        </LevelButton>
      </Link>
    );
  }

  return levels;
};

const getHoverRules = (isDemoGame, user) => {
  return css`
    transition: background-color 150ms ease-in-out;
    ${isDemoGame && !user && 'color: #fff'};

    &:hover {
      background-color: ${isDemoGame && !user ? '#007737' : 'var(--cx-hover-beige)'};
    }

    &:not(:hover) {
      background-color: ${isDemoGame && !user ? 'var(--cx-dark-green)' : 'var(--cx-beige)'};
    }
  `;
};

const CompanyLogoWrapper = styled.div`
  outline: none;

  ${({ usersMultipleGameData }) =>
    usersMultipleGameData &&
    css`
      cursor: pointer;
      transition: filter 100ms ease-in-out;

      &:hover {
        filter: brightness(111%);
      }

      &:not(:hover) {
        filter: brightness(100%);
      }
    `}
`;

const SocialMedia = styled.div`
  bottom: 0.8em;
  position: absolute;

  h4 {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    margin-bottom: 0.8em;
  }

  a:not(:last-of-type) {
    margin-right: 0.4em;
  }
`;

const MobileHeader = styled.header`
  display: flex;
  height: 60px;
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 2;
`;

const HamburgerMenuToggle = styled.div`
  align-items: center;
  background-color: var(--cx-dark-green);
  cursor: pointer;
  display: flex;
  flex-basis: 3.75rem;
  flex-grow: 1;
  flex-shrink: 0;
  height: 3.75rem;
  justify-content: center;
`;

const HamburgerMenu = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 146, 69, 0.9);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 400ms ease-in-out;

  a {
    color: #fff;
    font-size: 2.625rem;
    font-weight: 700;
    text-decoration: none;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-bottom: 0.25em;
    }
  }

  ${({ isHamburgerMenuIsOpen }) =>
    isHamburgerMenuIsOpen
      ? css`
          transform: translateX(0);
        `
      : css`
          transform: translateX(-100%);
        `}
`;

const Lock = styled.img`
  width: 1.125em;
  position: absolute;
`;

const LevelButton = styled.div`
  align-items: center;
  border-radius: 0.125em;
  display: flex;
  flex-direction: column;
  height: 6.25em;
  justify-content: center;
  max-width: 6.25em;
  text-align: center;
  width: 100%;

  p,
  span {
    font-weight: 700;
  }

  p {
    font-size: ${({ isDemoGame }) => (isDemoGame ? '1.1rem' : '3rem')};
    height: 1.104em;
    letter-spacing: 0.025em;
    line-height: 0.9em;
    pointer-events: none;
    ${({ isDemoGame }) =>
      isDemoGame &&
      css`
        position: relative;
        bottom: 0.2125em;
      `}
  }

  span {
    bottom: ${({ isDemoGame }) => (isDemoGame ? '-0.2em' : '0.75em')};
    display: block;
    font-size: 1rem;
    letter-spacing: 0.1em;
    pointer-events: none;
    position: relative;
    text-transform: uppercase;
  }

  ${({ locked }) =>
    locked
      ? css`
          cursor: default;
          pointer-events: none;
        `
      : css`
          border: 0.063em solid var(--cx-dark-green);
          cursor: pointer;
          pointer-events: auto;
        `};

  ${({ locked, selected }) =>
    !locked && selected
      ? css`
          background-color: var(--cx-dark-green);

          p {
            color: #fff;
          }

          span {
            color: #000;
          }

          p,
          span {
            transition: color 150ms ease-in-out;
          }
        `
      : !locked && !selected
      ? css`
          background-color: var(--cx-beige);
          border: 0.063em solid var(--cx-dark-green);
          cursor: pointer;
          pointer-events: auto;

          p,
          span {
            color: var(--cx-dark-beige);
            transition: color 150ms ease-in-out;
          }

          &:hover {
            background-color: var(--cx-light-green);

            p {
              color: #fff;
            }

            span {
              color: #000;
            }

            p,
            span {
              transition: color 150ms ease-in-out;
            }

            /* Whitens the left side of the 'X' for level 10 */
            &:last-of-type path:last-of-type {
              fill: #fff;
            }
          }
        `
      : css`
          background-color: rgba(239, 237, 228, 0.5);
          color: rgba(193, 190, 172, 0.3);
          cursor: default;
          pointer-events: none;

          &:last-of-type svg {
            opacity: 0.35;
          }
        `};

  transition: background-color 150ms ease-in-out;
`;

const Logo = styled.div`
  background-color: var(--cx-beige);
  width: 100%;
  height: 100%;
`;

const CompanyLogo = styled(Img)`
  width: 5rem;
  height: 5rem;
  flex-shrink: 0;
`;

const TabletHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  left: ${({ showCompanyLogo }) => (showCompanyLogo ? css`calc(50% + 5rem)` : '50%')};
  max-width: ${({ showCompanyLogo }) => (showCompanyLogo ? '81.25rem' : '76.25rem')};
  position: fixed;
  top: 0;
  transform: ${({ showCompanyLogo }) =>
    showCompanyLogo ? css`translateX(calc(-50% - 5rem))` : css`translateX(-50%)`};
  width: 100%;
  z-index: 2;

  @media screen and (min-width: 86.25rem) {
    transform: ${({ showCompanyLogo }) =>
      showCompanyLogo ? css`translateX(calc(-50% - 2.5rem))` : css`translateX(-50%)`};
  }
`;

const TabletHeader = styled.header`
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: space-between;
  max-width: 76.25rem;
  width: 100%;

  a {
    text-decoration: none;
    color: #000;
  }
`;

const CampaignXLogo = styled.img`
  height: 100%;
  margin-left: 0.625em;
  max-width: 14.274rem;

  @media screen and (min-width: 31.25em) {
    margin-left: 1.875em;
  }
`;

const Nav = styled.nav`
  align-items: center;
  display: flex;
  font-size: 1.125rem;
  font-weight: bold;
  height: 100%;
  letter-spacing: 0.1em;

  > a {
    height: 100%;
  }
`;

const Home = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 6.811em;

  ${getHoverRules()}
`;

const VerticalDivider = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 0.111em;

  span {
    height: 2.625em;
    background-color: #000;
    opacity: 0.2;
  }

  ${({ makeVerticalDividerBackgroundDarkBeige }) =>
    makeVerticalDividerBackgroundDarkBeige
      ? css`
          background-color: var(--cx-hover-beige);
          transition: background-color 150ms ease-in-out;
        `
      : css`
          background-color: var(--cx-beige);
          transition: background-color 150ms ease-in-out;
        `}
`;

const DropDownMenu = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  display: flex;
  font-size: 1rem;
  height: 8.75em;
  justify-content: space-between;
  left: 0;
  max-width: 100vw;
  padding: 0 1.639344262295082%;
  position: absolute;
  top: -3.75em;
  width: 100%;
  z-index: -1;

  @media screen and (min-width: 86.25rem) {
    max-width: 76.25rem;
  }

  a {
    margin: 0 0.85%;
    width: 100%;
    cursor: default;

    &:first-of-type {
      margin: 0 0.85% 0 0;
    }

    &:last-of-type {
      margin: 0 0 0 0.85%;
    }
  }

  &:hover + span {
    background-color: var(--cx-hover-beige);
    transition: background-color 150ms ease-in-out;
  }

  &:not(:hover) + span {
    background-color: var(--cx-beige);
    transition: background-color 150ms ease-in-out;
  }
`;

const Levels = styled.div`
  height: 100%;
  width: 6.211em;

  > span {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    &:hover {
      background-color: var(--cx-hover-beige);
      transition: background-color 150ms ease-in-out;
    }
  }

  &:hover {
    ${DropDownMenu} {
      transform: translate3d(0, 8.75em, 0);
      transition: transform 500ms ease-in-out;
    }
  }

  &:not(:hover) {
    ${DropDownMenu} {
      transform: translate3d(0, 0, 0);
      transition: transform 500ms ease-in-out;
    }
  }
`;

const StyledGreenDownArrow = styled(GreenDownArrow)`
  margin-left: 0.25em;
  width: 0.65em;
`;

const Rules = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 5.556em;

  ${getHoverRules()}
`;

const LogInAndOut = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 8em;

  ${({ isDemoGame, user }) => getHoverRules(isDemoGame, user)};
`;

const Scoreboard = styled.div`
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  text-transform: uppercase;
  width: 12.556em;

  &:hover {
    background-color: #007737;
    transition: background-color 150ms ease-in-out;
  }

  &:not(:hover) {
    background-color: var(--cx-dark-green);
    transition: background-color 150ms ease-in-out;
  }
`;

const StyledXLogo = styled(XLogo)`
  height: 2.5em;
  pointer-events: none;
  position: relative;
  top: 0.063em;
  width: 2.25em;

  ${({ selected }) =>
    selected
      ? css`
          path:last-of-type {
            fill: #fff;
          }
        `
      : css`
          path:last-of-type {
            fill: var(--cx-dark-green);
          }
        `};

  path:last-of-type {
    transition: fill 150ms ease-in-out;
  }
`;

export default Header;
