import * as React from 'react';

function SvgXsAndOs5(props) {
  return (
    <svg viewBox="0 0 164 206" fill="none" {...props}>
      <path
        d="M87.972 132.944C76.41 132.944 67 123.533 67 111.972 67 100.411 76.411 91 87.972 91c11.56 0 20.972 9.411 20.972 20.972 0 11.561-9.412 20.972-20.972 20.972zm0-29.046c-4.443 0-8.074 3.631-8.074 8.074s3.631 8.073 8.074 8.073 8.073-3.63 8.073-8.073a8.064 8.064 0 00-8.073-8.074zM34.52 154.466c-7.31 0-13.233-5.924-13.233-13.233S27.211 128 34.52 128s13.233 5.924 13.233 13.233-5.972 13.233-13.233 13.233zm0-18.297a5.117 5.117 0 00-5.112 5.112 5.117 5.117 0 005.112 5.111 5.117 5.117 0 005.111-5.111c-.047-2.819-2.34-5.112-5.111-5.112zM9.214 122.854a6.932 6.932 0 01-6.927-6.927A6.932 6.932 0 019.214 109a6.932 6.932 0 016.927 6.927 6.932 6.932 0 01-6.927 6.927zm0-9.602a2.67 2.67 0 00-2.675 2.675 2.67 2.67 0 002.675 2.675 2.67 2.67 0 002.675-2.675 2.67 2.67 0 00-2.675-2.675zM65.73 77.067a6.932 6.932 0 01-6.928-6.927 6.932 6.932 0 016.927-6.927 6.932 6.932 0 016.927 6.927 6.932 6.932 0 01-6.927 6.927zm0-9.603a2.67 2.67 0 00-2.676 2.676 2.67 2.67 0 002.675 2.675 2.67 2.67 0 002.675-2.675 2.67 2.67 0 00-2.675-2.676zM20.016 76.786l-4.777-4.73-5.255 5.207-5.207-5.207L0 76.786l5.255 5.255L0 87.296l4.777 4.729 5.207-5.207 5.255 5.207 4.777-4.73-5.254-5.254 5.254-5.255zM61.552 176.124L52.428 167l-9.984 9.984L32.41 167l-9.124 9.124 10.032 9.985-10.032 9.984 9.124 9.124 10.033-9.984 9.984 9.984 9.124-9.124-9.984-9.984 9.984-9.985zM57.996 33.584L46.343 48.942l13.436 10.142 11.653-15.359-13.436-10.141zM52.244 2.816l-10.37 13.668 15.133 11.423 10.37-13.668L52.244 2.816z"
        fill="#C1BEAC"
      />
      <path
        d="M39.606 19.579L22.88 7.156 11.092 22.435l16.726 12.422-12.89 16.788 15.285 11.353 12.89-16.788L54.892 30.93 39.606 19.58z"
        fill="#009245"
        fillOpacity={0.5}
      />
      <path
        d="M163.825 33.088l-14.319-22.333-24.473 15.684L109.358 2l-22.32 14.28 15.722 24.486L78.287 56.45l14.319 22.332 24.426-15.684 15.722 24.44 22.274-14.28-15.676-24.486 24.473-15.684zM58.983 88.745l-5.255-8.17-8.933 5.733-5.78-8.933-8.17 5.255 5.78 8.933-8.98 5.733 5.255 8.169 8.933-5.733 5.78 8.981 8.17-5.254-5.781-8.981 8.981-5.733z"
        fill="#C1BEAC"
      />
    </svg>
  );
}

export default SvgXsAndOs5;
