import React, { useState } from 'react';
import styled from 'styled-components';
import Player from 'react-player/vimeo';
import { motion, AnimatePresence } from 'framer-motion';
import XsAndOs5 from 'images/Home/XsAndOs5';
import XsAndOs6 from 'images/Home/XsAndOs6';
import playIcon from './play_icon.png';

const OverviewOrRulesVideo = ({ pathname, videoCode, viewportWidth }) => {
  const [playingVideo, setPlayingVideo] = useState();
  return (
    <Wrapper>
      <div>
        {pathname === '/' && (
          <>
            <h3>
              Unlocking Your Team’s Potential{viewportWidth < 768 ? ' ' : <br />}In The Virtual
              World
            </h3>
            <p>Watch Our Introduction Video Below</p>
          </>
        )}
        <div
          style={{
            border: viewportWidth >= 768 ? '20px solid #efede4' : 'none',
            position: 'relative'
          }}>
          {viewportWidth >= 768 && (
            <>
              <StyledXsAndOs5 />
              <StyledXsAndOs6 />
            </>
          )}
          <PlayerWrapper>
            <Player
              url={`https://player.vimeo.com/video/${videoCode}?transparent=true&autopause=0`}
              frameBorder="0"
              title="Campaign X"
              allow="autoplay; fullscreen"
              controls
              playing={playingVideo === videoCode}
              playsInline
              allowFullScreen
              width="100%"
              height="100%"
              style={{
                position: 'absolute',
                top: 0,
                left: 0
              }}
            />
            <AnimatePresence>
              {playingVideo !== videoCode && (
                <Overlay
                  key={videoCode}
                  initial={{ opacity: 1 }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                  onClick={() => setPlayingVideo(videoCode)}>
                  <motion.img
                    whileHover={{ scale: 1.1, filter: 'brightness(107.5%)' }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ scale: 1, filter: 'brightness(100%)' }}
                    src={playIcon}
                    alt="play icon"
                  />
                </Overlay>
              )}
            </AnimatePresence>
          </PlayerWrapper>
        </div>
      </div>
    </Wrapper>
  );
};

const StyledXsAndOs5 = styled(XsAndOs5)`
  position: absolute;
  top: 0;
  width: 10.188rem;
  left: -3.825rem;
  top: -3.5rem;
`;

const StyledXsAndOs6 = styled(XsAndOs6)`
  position: absolute;
  width: 14.625rem;
  bottom: -3.4rem;
  right: -6rem;
`;

const Wrapper = styled.section`
  padding: 0 0.625rem;

  @media screen and (min-width: 550px) {
    padding: 0 2rem;
  }

  > div {
    margin: 3.75rem auto 3.5rem;
    width: 100%;
    max-width: 1220px;

    h3 {
      text-align: center;
      color: var(--cx-dark-green);
      font-size: 1.5rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 0.75em;
    }

    p {
      color: #000;
      text-align: center;
      font-size: 1.125rem;
      margin-bottom: 1.6em;
    }

    @media screen and (min-width: 768px) {
      margin: 5.25rem auto 0;

      h3 {
        font-size: 2rem;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }
`;

const PlayerWrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
  border-radius: 0.125rem;
  overflow: hidden;
`;

const Overlay = styled(motion.div)`
  align-items: center;
  background-color: rgba(000, 000, 000, 0.2);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  img {
    width: 20%;
    min-width: 5.75rem;
    max-width: 7.5rem;
  }
`;

export default OverviewOrRulesVideo;
