import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import XLogo from 'images/Home/XLogo';
import { StaticQuery, graphql } from 'gatsby';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import GreenRightArrow from 'images/Home/GreenRightArrow';
import { CarouselProvider, Slider, Slide, CarouselContext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Carousel } from 'react-responsive-carousel';
import carouselIconData from 'local_data/carousel-icon-data';

const LevelButtons = ({ currentSlide, setCurrentSlide, viewportWidth }) => {
  const carouselContext = useContext(CarouselContext);
  const [mouseIsHoveredOverLevelButtons, setMouseIsHoveredOverLevelButtons] = useState(false);
  let moveCarouselToNextSlide;

  useEffect(() => {
    moveCarouselToNextSlide = setInterval(() => {
      if (!mouseIsHoveredOverLevelButtons) {
        if (viewportWidth < 1200) {
          carouselContext.setStoreState({ currentSlide: currentSlide + 1 });
        } else {
          setCurrentSlide((prevSlide) => (prevSlide < 9 ? prevSlide + 1 : 0));
        }
      }
    }, 5000);
    return () => clearInterval(moveCarouselToNextSlide);
  }, [mouseIsHoveredOverLevelButtons, carouselContext, currentSlide, viewportWidth]);

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }

    if (viewportWidth < 1200) {
      carouselContext.subscribe(onChange);
      return () => carouselContext.unsubscribe(onChange);
    }
  }, [carouselContext, viewportWidth]);

  const handleLevelSelection = (e) => {
    e.stopPropagation();
    let { level } = e.target.dataset;
    if (level) {
      level = parseInt(level.charAt(0) === '0' ? level.slice(1) : level, 10);
      setCurrentSlide((prevSlide) => (prevSlide !== level - 1 ? level - 1 : prevSlide));
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
      onMouseEnter={() => setMouseIsHoveredOverLevelButtons((prevValue) => !prevValue)}
      onMouseLeave={() => setMouseIsHoveredOverLevelButtons((prevValue) => !prevValue)}>
      <StyledGreenLeftArrow
        onClick={() =>
          viewportWidth < 1200
            ? carouselContext.setStoreState({ currentSlide: currentSlide - 1 })
            : setCurrentSlide((prevSlide) => prevSlide - 1)
        }
        disabled={currentSlide === 0}
      />
      <SliderExt onClick={(e) => viewportWidth >= 1200 && handleLevelSelection(e)}>
        <SlideExt index={0}>
          <LevelButton data-level="01" selected={currentSlide === 0}>
            <p>
              01<span>Level</span>
            </p>
          </LevelButton>
        </SlideExt>
        <SlideExt index={1}>
          <LevelButton data-level="02" selected={currentSlide === 1}>
            <p>
              02<span>Level</span>
            </p>
          </LevelButton>
        </SlideExt>
        <SlideExt index={2}>
          <LevelButton data-level="03" selected={currentSlide === 2}>
            <p>
              03<span>Level</span>
            </p>
          </LevelButton>
        </SlideExt>
        <SlideExt index={3}>
          <LevelButton data-level="04" selected={currentSlide === 3}>
            <p>
              04<span>Level</span>
            </p>
          </LevelButton>
        </SlideExt>
        <SlideExt index={4}>
          <LevelButton data-level="05" selected={currentSlide === 4}>
            <p>
              05<span>Level</span>
            </p>
          </LevelButton>
        </SlideExt>
        <SlideExt index={5}>
          <LevelButton data-level="06" selected={currentSlide === 5}>
            <p>
              06<span>Level</span>
            </p>
          </LevelButton>
        </SlideExt>
        <SlideExt index={6}>
          <LevelButton data-level="07" selected={currentSlide === 6}>
            <p>
              07<span>Level</span>
            </p>
          </LevelButton>
        </SlideExt>
        <SlideExt index={7}>
          <LevelButton data-level="08" selected={currentSlide === 7}>
            <p>
              08<span>Level</span>
            </p>
          </LevelButton>
        </SlideExt>
        <SlideExt index={8}>
          <LevelButton data-level="09" selected={currentSlide === 8}>
            <p>
              09<span>Level</span>
            </p>
          </LevelButton>
        </SlideExt>
        <SlideExt index={9}>
          <LevelButton key="10" data-level="10" selected={currentSlide === 9}>
            <StyledXLogo alt="campaign x logo" selected={currentSlide === 9} />
            <span
              style={{
                position: 'relative',
                top: '0.325em'
              }}>
              Level
            </span>
          </LevelButton>
        </SlideExt>
      </SliderExt>
      <StyledGreenRightArrow
        onClick={() =>
          viewportWidth < 1200
            ? carouselContext.setStoreState({ currentSlide: currentSlide + 1 })
            : setCurrentSlide((prevSlide) => prevSlide + 1)
        }
        disabled={currentSlide === 9}
      />
    </div>
  );
};

const HowItWorks = ({ viewportWidth }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <Wrapper>
      <InnerWrapper>
        <h2>How It Works:</h2>
        <h3>Maximum engagement for all players</h3>
        <h3>There are 10 Levels, customised to your timeframe</h3>
      </InnerWrapper>
      <PureReactCarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={10}
        visibleSlides={viewportWidth < 1200 ? 1 : 10}
        transitionTime={580}
        isIntrinsicHeight>
        <LevelButtons
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          viewportWidth={viewportWidth}
        />
      </PureReactCarouselProvider>
      <InnerWrapper>
        <StaticQuery
          query={graphql`
            {
              levelIcons: allFile(
                filter: {
                  sourceInstanceName: { eq: "images" }
                  relativeDirectory: { eq: "Shared/LevelIcons/BlackStroke" }
                  name: { regex: "/_/" }
                }
                sort: { fields: [name], order: ASC }
              ) {
                edges {
                  node {
                    publicURL
                  }
                }
              }
              levelData: allFile(
                filter: { sourceInstanceName: { eq: "levelData" } }
                sort: { fields: [name], order: ASC }
              ) {
                edges {
                  node {
                    childMarkdownRemark {
                      frontmatter {
                        title
                        level
                        homepageCarouselSummary
                        scoring {
                          challenge {
                            points
                            detail
                          }
                          teamGame {
                            points
                            detail
                          }
                        }
                        week
                      }
                    }
                  }
                }
              }
            }
          `}
          render={({ levelIcons, levelData }) => (
            <LevelOverviewCarousel
              selectedItem={currentSlide + 1}
              showThumbs={false}
              showArrows={false}
              showIndicators={false}
              showStatus={false}>
              {levelData.edges.map(({ node }) => {
                const {
                  level,
                  title,
                  homepageCarouselSummary,
                  scoring,
                  week
                } = node.childMarkdownRemark.frontmatter;
                return (
                  <LevelOverview key={level}>
                    <LevelIconWrapper>
                      <LevelIcon
                        src={levelIcons.edges[level - 1].node.publicURL}
                        alt={carouselIconData[level - 1].alt}
                        maxWidth={carouselIconData[level - 1].maxWidth}
                        viewportWidth={viewportWidth}
                      />
                      {viewportWidth < 1000 && (
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}>
                          <Points
                            dangerouslySetInnerHTML={{
                              __html: `Points ${scoring.challenge.points + scoring.teamGame.points}`
                            }}
                          />
                          <Week dangerouslySetInnerHTML={{ __html: `Week: ${week}` }} />
                        </div>
                      )}
                    </LevelIconWrapper>
                    <InfoWrapper>
                      <h4>Level</h4>
                      <h2 dangerouslySetInnerHTML={{ __html: title }} />
                      <h4>Summary</h4>
                      <Summary dangerouslySetInnerHTML={{ __html: homepageCarouselSummary }} />
                      {viewportWidth >= 1000 && (
                        <>
                          <Points
                            dangerouslySetInnerHTML={{
                              __html: `Points ${scoring.challenge.points + scoring.teamGame.points}`
                            }}
                          />
                          <Week dangerouslySetInnerHTML={{ __html: `Week: ${week}` }} />
                        </>
                      )}
                    </InfoWrapper>
                  </LevelOverview>
                );
              })}
            </LevelOverviewCarousel>
          )}
        />
      </InnerWrapper>
    </Wrapper>
  );
};

export default HowItWorks;

const Wrapper = styled.section`
  background-color: rgba(239, 237, 228, 0.3);
  padding-top: 3.45em;
  padding-bottom: 0.85em;

  @media screen and (min-width: 62.5rem) {
    padding-bottom: 4.7em;
  }

  .carousel .slide {
    background-color: unset;
    text-align: unset;
  }
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 73.75em;
  padding: 0 0.75rem;

  @media screen and (min-width: 62.5rem) {
    padding: unset;
  }

  > h2 {
    color: #000;
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 0.25em;
    text-align: center;
    font-size: 2rem;

    @media screen and (min-width: 34.375em) {
      font-size: 3rem;
    }
  }

  > h3 {
    font-weight: 700;
    text-align: center;
    font-size: 1.125rem;

    @media screen and (min-width: 34.375em) {
      font-size: 2rem;
    }

    &:nth-of-type(1) {
      color: var(--cx-dark-green);
      margin-bottom: 0.35em;
    }

    &:nth-of-type(2) {
      margin-bottom: 1.2em;

      @media screen and (min-width: 62.5rem) {
        margin-bottom: 1.9em;
      }
    }
  }
`;

const PureReactCarouselProvider = styled(CarouselProvider)`
  padding: 0 1.25em;
  position: relative;
`;

const SliderExt = styled(Slider)`
  width: 8.75em;

  @media screen and (min-width: 75rem) {
    width: 75em;
  }
`;

const SlideExt = styled(Slide)`
  margin-left: 1.25em;

  @media screen and (min-width: 75rem) {
    margin: 0 0.63em;
  }
`;

const LevelButton = styled.div`
  align-items: center;
  border-radius: 0.125em;
  border: 0.063em solid var(--cx-dark-green);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 6.25em;
  justify-content: center;
  max-width: 6.25em;
  text-align: center;
  width: 100%;

  &:last-of-type {
    svg {
      pointer-events: none;
    }
  }

  p,
  span {
    font-weight: 700;
  }

  p {
    font-size: 3rem;
    height: 1.104em;
    letter-spacing: 0.025em;
    line-height: 0.9em;
    pointer-events: none;
  }

  span {
    bottom: 0.75em;
    display: block;
    font-size: 1rem;
    letter-spacing: 0.1em;
    pointer-events: none;
    position: relative;
    text-transform: uppercase;
  }

  ${({ selected }) =>
    selected
      ? css`
          background-color: var(--cx-dark-green);

          p {
            color: #fff;
          }

          span {
            color: #000;
          }

          p,
          span {
            transition: color 150ms ease-in-out;
          }
        `
      : css`
          background-color: var(--cx-beige);

          p,
          span {
            color: var(--cx-dark-beige);
            transition: color 150ms ease-in-out;
          }
        `};

  transition: background-color 150ms ease-in-out;
`;

const StyledXLogo = styled(XLogo)`
  height: 2.5em;
  width: 2.25em;

  ${({ selected }) =>
    selected
      ? css`
          path:last-of-type {
            fill: #fff;
          }
        `
      : css`
          path:last-of-type {
            fill: var(--cx-dark-green);
          }
        `};

  path:last-of-type {
    transition: fill 150ms ease-in-out;
  }
`;

const StyledGreenRightArrow = styled(GreenRightArrow)`
  position: relative;
  width: 2.563em;
  flex-shrink: 0;

  @media screen and (min-width: 75rem) {
    margin-left: 0.625em;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          pointer-events: none;

          path {
            fill: var(--cx-dark-beige);
          }
        `
      : css`
          cursor: pointer;
          pointer-events: auto;

          path {
            fill: var(--cx-dark-green);
          }
        `}

  path {
    transition: fill 150ms ease-in-out;
  }
`;

const StyledGreenLeftArrow = styled(StyledGreenRightArrow)`
  transform: scaleX(-1);

  @media screen and (min-width: 75rem) {
    margin-left: unset;
    margin-right: 0.625em;
  }
`;

const LevelOverviewCarousel = styled(Carousel)`
  margin-top: 1.688em;

  @media screen and (min-width: 34.375rem) {
    margin-top: 2.1em;
  }

  @media screen and (min-width: 48em) {
    margin-top: 2.9em;
  }

  @media screen and (min-width: 62.5rem) {
    margin-top: 6.525em;
  }
`;

const LevelIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 62.5rem) {
    max-width: 29.938em;
  }
`;

const LevelIcon = styled.img`
  max-width: ${({ maxWidth, viewportWidth }) => viewportWidth < 550 ? '6rem' : viewportWidth < 768 ? '8rem' : viewportWidth < 1000 ? '12rem' : maxWidth};
  width: 100%;
  margin-right: 2.938rem;

  @media screen and (min-width: 62.5rem) {
    margin-right: unset;
  }
`;

const InfoWrapper = styled.div`
  margin: 0 auto;
  max-width: 43.813em;
  width: 100%;

  h2 {
    color: #000;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1em;
    text-align: center;

    @media screen and (min-width: 34.375rem) {
      font-size: 3rem;
    }

    @media screen and (min-width: 62.5rem) {
      margin-bottom: 0.5em;
      padding-right: 0.75em;
      text-align: left;
    }
  }

  h4 {
    color: var(--cx-dark-green);
    font-size: 0.75rem;
    font-weight: 600;
    margin: 1.75em auto 0.583em;
    text-transform: uppercase;
    text-align: center;

    @media screen and (min-width: 34.375rem) {
      font-size: 1rem;
      margin: 2.313em auto 1em;
    }

    @media screen and (min-width: 62.5rem) {
      margin: 0 auto 0.25em;
      text-align: left;
    }
  }
`;

const LevelOverview = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: 62.5rem) {
    flex-direction: row;
  }
`;

const Summary = styled.p`
  font-size: 1.125rem;
  line-height: 1.333em;
  margin: 0 auto;
  max-width: 19.556em;

  @media screen and (min-width: 34.375rem) {
    font-size: 1.5rem;
    max-width: 18.750em;
  }

  @media screen and (min-width: 62.5rem) {
    padding-right: 1.5em;
    margin: 0 0 1.75em;
    max-width: unset;
  }
`;

const Points = styled.div`
  align-items: center;
  background-color: var(--cx-dark-green);
  border-radius: 0.125em;
  color: #fff;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 600;
  height: 2.875em;
  justify-content: center;
  width: 8.5em;

  @media screen and (min-width: 62.5rem) {
    width: 10.375em;
  }
`;

const Week = styled(Points)`
  background-color: var(--cx-dark-beige);
  width: 8.5em;
  margin-top: 0.625em;

  @media screen and (min-width: 62.5rem) {
    width: 10.375em;
    margin-top: unset;
    margin-left: 1.25em;
  }
`;
