import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import defaultThumbnail from './campaign_x_homepage_screenshot.jpg';

export default function SEO({
  children,
  lang,
  meta,
  pageSpecificTitle,
  pageSpecificSiteTitle,
  pageSpecificDescription,
  pageSpecificThumbnail,
  pageSpecificThumbnailAlt
}) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              defaultSiteTitle
              defaultDescription
              defaultThumbnailAlt
              siteUrl
              twitterPage
              author
            }
          }
        }
      `}
      render={(data) => {
        const {
          defaultSiteTitle,
          defaultDescription,
          defaultThumbnailAlt,
          siteUrl,
          twitterPage,
          author
        } = data.site.siteMetadata;
        const siteTitle = pageSpecificSiteTitle || defaultSiteTitle;
        const description = pageSpecificDescription || defaultDescription;
        const thumbnail = pageSpecificThumbnail || defaultThumbnail;
        const thumbnailAlt = pageSpecificThumbnailAlt || defaultThumbnailAlt;
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={pageSpecificTitle}
            titleTemplate={`%s | ${siteTitle}`}
            meta={[
              {
                name: 'og:site_name',
                content: defaultSiteTitle
              },
              {
                name: `description`,
                content: description
              },
              {
                name: `og:locale`,
                content: 'en_GB'
              },
              {
                property: `og:url`,
                content: siteUrl
              },
              {
                property: `og:title`,
                content: pageSpecificTitle
              },
              {
                property: `og:description`,
                content: description
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                property: `og:image`,
                content: `${siteUrl}${thumbnail}`
              },
              {
                property: `og:image:url`,
                content: `${siteUrl}${thumbnail}`
              },
              {
                property: `og:image:secure_url`,
                content: `${siteUrl}${thumbnail}`
              },
              {
                property: `og:image:alt`,
                content: thumbnailAlt
              },
              {
                property: 'og:image:width',
                content: '1200'
              },
              {
                property: 'og:image:height',
                content: '630'
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:image`,
                content: `${siteUrl}${thumbnail}`
              },
              {
                property: 'twitter:image:alt',
                content: thumbnailAlt
              },
              {
                name: `twitter:site`,
                content: twitterPage
              },
              {
                name: `twitter:creator`,
                content: twitterPage
              },
              {
                name: `twitter:title`,
                content: siteTitle
              },
              {
                name: `twitter:description`,
                content: description
              },
              {
                name: `author`,
                content: author
              }
            ].concat(meta)}>
            <link rel="stylesheet" href="https://use.typekit.net/nhm4cqa.css" />
            <script src="https://cdn.polyfill.io/v2/polyfill.min.js" />
            {children}
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: []
};
