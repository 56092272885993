// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ie-not-supported-js": () => import("./../../../src/pages/ie-not-supported.js" /* webpackChunkName: "component---src-pages-ie-not-supported-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-level-1-demo-js": () => import("./../../../src/pages/level-1-demo.js" /* webpackChunkName: "component---src-pages-level-1-demo-js" */),
  "component---src-pages-level-1-js": () => import("./../../../src/pages/level-1.js" /* webpackChunkName: "component---src-pages-level-1-js" */),
  "component---src-pages-level-10-js": () => import("./../../../src/pages/level-10.js" /* webpackChunkName: "component---src-pages-level-10-js" */),
  "component---src-pages-level-2-js": () => import("./../../../src/pages/level-2.js" /* webpackChunkName: "component---src-pages-level-2-js" */),
  "component---src-pages-level-3-js": () => import("./../../../src/pages/level-3.js" /* webpackChunkName: "component---src-pages-level-3-js" */),
  "component---src-pages-level-4-js": () => import("./../../../src/pages/level-4.js" /* webpackChunkName: "component---src-pages-level-4-js" */),
  "component---src-pages-level-5-js": () => import("./../../../src/pages/level-5.js" /* webpackChunkName: "component---src-pages-level-5-js" */),
  "component---src-pages-level-6-js": () => import("./../../../src/pages/level-6.js" /* webpackChunkName: "component---src-pages-level-6-js" */),
  "component---src-pages-level-7-js": () => import("./../../../src/pages/level-7.js" /* webpackChunkName: "component---src-pages-level-7-js" */),
  "component---src-pages-level-8-js": () => import("./../../../src/pages/level-8.js" /* webpackChunkName: "component---src-pages-level-8-js" */),
  "component---src-pages-level-9-js": () => import("./../../../src/pages/level-9.js" /* webpackChunkName: "component---src-pages-level-9-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */)
}

