import React from 'react';
import styled from 'styled-components';

const AboutCampaignX = ({ viewportWidth }) => (
  <Wrapper>
    <h3>About Campaign X</h3>
    <p>
      Campaign X is a 10-step marketing strategy challenge, where the players (your team) are
      required to develop a best in class, commercially ready campaign, that they will launch
      together.
      <br />
      <br />
      <b>
        By collaborating with our clients’ marketing leaders, we combine proven strategic marketing
        processes, team-building gamification and online collaboration tools, to unlock the
        innovative power of teams.
      </b>
    </p>
  </Wrapper>
);

const Wrapper = styled.section`
  margin-bottom: 3.6em;

  @media screen and (min-width: 450px) {
    margin-bottom: 8.375em;
  }

  h3,
  p {
    max-width: 58.25rem;
    margin: 0 auto;
  }

  h3 {
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    color: var(--cx-dark-green);
    text-transform: uppercase;
    text-align: center;
    margin: 2.5em auto 1.4em;

    @media screen and (min-width: 450px) {
      font-size: 2rem;
      margin: 4.438em auto 2.5em;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.333em;

    @media screen and (min-width: 450px) {
      font-size: 1.5rem;
    }
  }
`;

export default AboutCampaignX;
