import React, { createContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';

const GameDataContext = createContext();

export const GameDataProvider = ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        gameData: allFile(
          filter: { sourceInstanceName: { eq: "gameData" }, ext: { eq: ".md" } }
        ) {
          edges {
            node {
              childMarkdownRemark {
                frontmatter {
                  title
                  logo {
                    name
                    childImageSharp {
                      fixed(width: 80, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                      }
                    }
                  }
                  welcomeVideoZoomLink
                  liveStreamCode
                  hideLiveStreamAfterLaunch
                  weekInProgress
                  highestActiveLevel
                  uniqueDocs {
                    level
                    link {
                      publicURL
                    }
                  }
                  teams {
                    name
                    players
                    points {
                      level01 {
                        challengePoints
                        gamePoints
                      }
                      level02 {
                        challengePoints
                        gamePoints
                      }
                      level03 {
                        challengePoints
                        gamePoints
                      }
                      level04 {
                        challengePoints
                        gamePoints
                      }
                      level05 {
                        challengePoints
                        gamePoints
                      }
                      level06 {
                        challengePoints
                        gamePoints
                      }
                      level07 {
                        challengePoints
                        gamePoints
                      }
                      level08 {
                        challengePoints
                        gamePoints
                      }
                      level09 {
                        challengePoints
                        gamePoints
                      }
                      level10 {
                        challengePoints
                        gamePoints
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ gameData }) => (
      <GameDataContext.Provider
        value={{
          gameData
        }}>
        {children}
      </GameDataContext.Provider>
    )}
  />
);

export default GameDataContext;
