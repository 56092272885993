import React from 'react';
import styled from 'styled-components';
import XsAndOs1 from 'images/Home/XsAndOs1';

const TheResult = ({ viewportWidth }) => (
  <Wrapper>
    <h3>The Result</h3>
    <p>A game-changing competitive advantage, planned, created and owned by your team.</p>
    {viewportWidth >= 1180 && <StyledXsAndOs1 />}
  </Wrapper>
);

const Wrapper = styled.section`
  align-items: center;
  background-color: var(--cx-beige);
  display: flex;
  flex-direction: column;
  /* height: 16.25em; */
  padding: 3.2em 0.5em 3.3em;
  position: relative;

  @media screen and (min-width: 28.125em) {
    /* font-size: 2rem; */
    /* padding: 3.95em 0 3.625em; */
  }

  h3,
  p {
    position: relative;
    z-index: 1;
  }

  h3 {
    color: var(--cx-dark-green);
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    margin-bottom: 0.525em;
    text-transform: uppercase;

    @media screen and (min-width: 62.5rem) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.5em;
    text-align: center;

    @media screen and (min-width: 31.25rem) {
      width: 80%;
    }

    @media screen and (min-width: 62.5rem) {
      font-size: 2rem;
      width: 23em;
    }
  }
`;

const StyledXsAndOs1 = styled(XsAndOs1)`
  position: absolute;
  right: -4.938em;
  top: 3em;
  width: 21.438em;
`;

export default TheResult;
