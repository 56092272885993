import React from 'react';
import styled from 'styled-components';

const GameSelect = ({ usersMultipleGameData, handleGameSelection, netlifyIdentity }) => (
  <Wrapper>
    <h2>Game Select</h2>
    <p>Select Your Campaign X</p>
    {usersMultipleGameData
      .map(({ node }) => {
        const game = node.childMarkdownRemark.frontmatter;
        return (
          <GameTitle onClick={() => handleGameSelection(game)} key={game.title}>
            {game.title}
          </GameTitle>
        );
      })}
    <Logout onClick={() => netlifyIdentity.logout()}>
      <button type="button">Logout</button>
    </Logout>
  </Wrapper>
);

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  h2 {
    font-size: 2rem;
    margin-top: 1.575em;
    margin-bottom: 0.2em;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1.6em;
  }
`;

const GameTitle = styled.div`
  align-items: center;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  font-weight: 600;
  height: 2.875em;
  justify-content: center;
  margin-bottom: 0.313em;
  transition: background-color 150ms ease-in-out;
  width: 14.313em;

  &:hover {
    background-color: var(--cx-dark-green);
  }

  &:not(:hover) {
    background-color: #000;
  }
`;

const Logout = styled.div`
  align-items: center;
  background-color: var(--cx-beige);
  display: flex;
  height: 5.375rem;
  justify-content: center;
  margin-top: 0.625rem;
  width: 100%;

  button {
    background-color: #fff;
    border-radius: 0.125em;
    border: none;
    color: #000;
    cursor: pointer;
    font-family: proxima-nova, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    height: 3em;
    outline: none;
    text-transform: uppercase;
    transition: transform 175ms ease-in-out;
    width: 8.75em;

    &:hover {
      transform: scale3d(1.075, 1.075, 1.075);
    }

    &:not(:hover) {
      transform: scale3d(1, 1, 1);
    }
  }
`;

export default GameSelect;
