import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import SmallQuotes from 'images/Shared/Inspiration/SmallQuotes';
import LargeQuotes from 'images/Shared/Inspiration/LargeQuotes';

const Inspiration = ({
  img,
  author,
  quote,
  quoteSmallFontSize,
  quoteLargeFontSize,
  maxWidth,
  textWrapperTop = '7.5em',
  viewportWidth
}) => (
  <Wrapper>
    <TextWrapper textWrapperTop={textWrapperTop}>
      {viewportWidth < 768 ? <StyledSmallQuotes /> : <StyledLargeQuotes />}
      <H4>Inspiration</H4>
      <Quote
        dangerouslySetInnerHTML={{ __html: quote }}
        quoteSmallFontSize={quoteSmallFontSize}
        quoteLargeFontSize={quoteLargeFontSize}
      />
      <Author dangerouslySetInnerHTML={{ __html: `— ${author}` }} />
    </TextWrapper>
    <BlackAndWhiteImg fluid={img.childImageSharp.fluid} alt={img.name} maxWidth={maxWidth} />
  </Wrapper>
);

const Wrapper = styled.section`
  background-color: rgba(239, 237, 228, 0.3);
  position: relative;

  @media screen and (min-width: 48rem) {
    height: 38.75em;
  }
`;

const BlackAndWhiteImg = styled(Img)`
  height: 26.063em;
  margin-top: 1em;
  max-width: 32.5em;

  @media screen and (min-width: 48rem) {
    max-width: ${({ maxWidth }) => maxWidth};
    margin-top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
  }
`;

const StyledSmallQuotes = styled(SmallQuotes)`
  position: absolute;
  width: 3.5em;
  top: 1.35em;
  z-index: -1;
`;

const StyledLargeQuotes = styled(LargeQuotes)`
  left: -7.5em;
  position: absolute;
  top: 2.4em;
  width: 10.688em;
  z-index: -1;
`;

const TextWrapper = styled.div`
  position: relative;
  left: 0.625rem;
  top: 2.375em;
  max-width: 23.5em;

  @media screen and (min-width: 25em) and (max-width: 48.063em) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 48rem) {
    top: ${({ textWrapperTop }) => textWrapperTop};
    position: absolute;
    left: 50.69%;
    max-width: unset;
  }
`;

const H4 = styled.h4`
  font-size: 1rem;
  left: 0.125em;
  letter-spacing: 0.1em;
  margin-bottom: 1.2em;
  position: relative;
  text-transform: uppercase;

  /* margin-bottom: 0.95em; */
`;

const Quote = styled.h3`
  color: var(--cx-dark-green);
  font-size: ${({ quoteSmallFontSize }) => quoteSmallFontSize};
  line-height: 1.125em;
  margin-bottom: 0.5em;
  max-width: 36.625rem;
  padding-right: 1rem;

  @media screen and (min-width: 48rem) {
    font-size: ${({ quoteLargeFontSize }) => quoteLargeFontSize};
  }
`;

const Author = styled.p`
  color: var(--cx-dark-green);
  font-size: 1.5rem;

  @media screen and (min-width: 48rem) {
    font-size: 2rem;
  }

  span {
    display: block;
    font-size: 0.625em;
    margin-left: 1.8em;
  }
`;

export default Inspiration;
