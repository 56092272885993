import * as React from 'react';

function SvgXLogo(props) {
  return (
    <svg viewBox="0 0 37 40" fill="none" {...props}>
      <path
        d="M20.466 24.236L32.301 40h4.203l-13.96-18.227-2.078 2.463zM22.631 17.102L35.628 0h-4.203L20.593 14.522l2.038 2.58z"
        fill="#000"
      />
      <path d="M6.126 0H1.862l14.842 19.489L.987 39.999h4.21L20.72 19.6 6.126 0z" fill="#009245" />
    </svg>
  );
}

export default SvgXLogo;
