import * as React from 'react';

function SvgLargeQuotes(props) {
  return (
    <svg viewBox="0 0 173 135" fill="none" {...props}>
      <path
        d="M48.786 1.61l-.29-.233-.306.212C19.502 21.443.5 52.4.5 87.087c0 30.144 19.556 47.413 41.522 47.413 20.094 0 36.912-16.78 36.912-36.835 0-20.04-14.038-33.615-31.842-33.615-1.862 0-3.844.117-5.542.35-.848.117-1.634.264-2.301.444-.216.058-.424.12-.62.188 2.216-7.895 7.303-16.386 13.627-23.903 6.596-7.84 14.498-14.578 21.803-18.45l.673-.357-.595-.476L48.787 1.61zm93.563-.003l-.29-.228-.303.21c-28.692 19.856-47.229 50.816-47.229 85.498 0 15.055 4.76 26.911 12.29 35.008 7.53 8.096 17.805 12.405 28.771 12.405 20.559 0 36.912-16.784 36.912-36.835 0-20.034-14.033-33.615-31.381-33.615-1.862 0-3.844.117-5.542.35-.849.117-1.634.264-2.301.444a9.46 9.46 0 00-.65.198c2.009-7.892 7.098-16.387 13.483-23.912 6.652-7.84 14.67-14.578 21.977-18.451l.68-.36-.606-.476-25.811-20.236z"
        stroke="#000"
        strokeOpacity={0.2}
      />
    </svg>
  );
}

export default SvgLargeQuotes;
