import * as React from 'react';

function SvgGreenDownArrow(props) {
  return (
    <svg viewBox="0 0 12 12" fill="none" {...props}>
      <path d="M1 4.803l4.803 4.804 4.804-4.804" stroke="#009245" strokeWidth={2} />
    </svg>
  );
}

export default SvgGreenDownArrow;
