import React from 'react';
import styled from 'styled-components';
import brainIcon from 'images/Home/game_overview_icons/brain_icon.svg';
import threePeopleTalkingIcon from 'images/Home/game_overview_icons/three_people_talking_icon.svg';
import lightbulbIcon from 'images/Home/game_overview_icons/lightbulb_icon.svg';

const GameOverview = ({ viewportWidth }) => (
  <Wrapper>
    <p>
      Campaign X provides some healthy competition, ideally splitting the main group into sub-teams, where
      they will compete in some parts and collaborate in others, have some fun, and hopefully win
      prizes along the way.
    </p>
    <Features>
      <Feature>
        <div>
          <img src={brainIcon} alt="brain" />
          <p>Strategy Challenges</p>
        </div>
        <div>
          <p>
            Each level has a strategy challenge requiring teams to collaborate, with points awarded
            depending on degrees of difficulty. Some levels get more points than others.
          </p>
        </div>
      </Feature>
      <Feature>
        <div>
          <img src={threePeopleTalkingIcon} alt="three people talking" />
          <p>Team Play Experiences</p>
        </div>
        <div>
          <p>
            Each level also has team building activities delivered through the Campaign X app, with
            points awarded based on completion. These can be icebreakers, precursors to the main
            strategy challenge, or just random fun, but they could be decisive.
          </p>
        </div>
      </Feature>
      <Feature>
        <div>
          <img src={lightbulbIcon} alt="lightbulb" />
          <p>Settling The Scores</p>
        </div>
        <div>
          <p>
            There is a scoreboard. It is updated at the end of each level. The judges’ decisions are
            final!
          </p>
        </div>
      </Feature>
    </Features>
  </Wrapper>
);

const Wrapper = styled.section`
  margin-bottom: 3.7em;

  @media screen and (min-width: 48rem) {
    margin-bottom: 8.063em;
  }

  > p:first-of-type {
    font-size: 1.25rem;
    line-height: 1.333em;
    margin: 2.1em auto 1.5em;

    @media screen and (min-width: 48rem) {
      font-size: 1.5rem;
      margin: 3.875em auto 3.625em;
      max-width: 38.833em;
    }
  }
`;

const Features = styled.div`
  display: grid;
  grid-gap: 0.875em;
  grid-template-areas:
    "feature1"
    "feature2"
    "feature3";

  @media screen and (min-width: 55.313rem) {
    justify-content: center;
    grid-template-areas:
    "feature1 feature2"
    "feature3 feature3";
  }

  @media screen and (min-width: 84.375rem) {
    grid-template-areas:
    "feature1 feature2 feature3";
    grid-gap: 0em;
    justify-content: space-between;
    width: 1180px;
  }
`;

const Feature = styled.div`
  background-color: rgba(239, 237, 228, 0.3);
  border: 0.063em solid var(--cx-dark-green);
  height: 31.563rem;

  @media screen and (min-width: 28.125rem) {
    margin: 0 auto;
    max-width: 23.688em;
  }

  &:nth-of-type(1) {
    grid-area: feature1;
  }

  &:nth-of-type(1) {
    grid-area: feature2;
  }

  &:nth-of-type(1) {
    grid-area: feature3;
  }

  @media screen and (min-width: 48rem) {

  }

  &:not(:last-of-type) {
    /* margin-bottom: 0.875em; */
  }

  div:first-of-type {
    align-items: center;
    background-color: var(--cx-dark-green);
    display: flex;
    flex-direction: column;
    height: 17.438em;
    justify-content: center;

    img {
      height: 9.25em;
      margin-top: 0.9em;
    }

    p {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 1.65em;
    }
  }

  div:last-of-type {
    /* height: 14.125em; */
    padding: 1.875em 1.875em 0.9375em 1.875em;

    p {
      font-size: 1.125rem;
      line-height: 1.333em;
    }
  }
`;

export default GameOverview;
