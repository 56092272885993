import React from 'react';
import styled from 'styled-components';
import mailIconSVG from 'images/Shared/mail_icon.svg';

export default ({ toggleContactUsModal }) => (
  <a
    onClick={() => toggleContactUsModal()}
    style={{ textDecoration: 'none', position: 'relative', zIndex: 2 }}>
    <ContactUsButton className="contact-button">
      <div>Contact Us</div>
      <MailIcon>
        <img src={mailIconSVG} alt="mail icon" />
      </MailIcon>
    </ContactUsButton>
  </a>
);

const MailIcon = styled.div`
  width: 48px;
  height: 48px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.125);
  transition: border-radius 400ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 1.625rem;
  }
`;

const ContactUsButton = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 10.375rem 3rem;
  grid-template-rows: 3rem;
  perspective: 550px;
  perspective-origin: 166px;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;

  @media screen and (min-width: 48rem) {
    bottom: 1.5rem;
    right: 3rem;
  }

  > div:first-of-type {
    transition: 400ms transform ease-in-out;
    transform-origin: right;
    align-items: center;
    background-color: #000;
    border-radius: 0.125rem 0 0 0.125rem;
    color: #fff;
    display: flex;
    font-weight: 700;
    justify-content: center;
    text-transform: uppercase;
  }

  rect {
    transition: fill 400ms ease-in-out;
  }

  path {
    transition: stroke 400ms ease-in-out;
  }

  &:hover {
    > div:first-of-type {
      transform: rotate3d(0, 1, 0, 0deg);
    }

    rect {
      fill: var(--cx-dark-green);
    }

    ${MailIcon} {
      background-color: var(--cx-dark-green);
      border-radius: 0 0.125rem 0.125rem 0;
    }
  }

  &:not(:hover) {
    > div:first-of-type {
      transform: rotate3d(0, 1, 0, -90deg);
    }

    rect {
      background-color: #000;
    }

    ${MailIcon} {
      background-color: #000;
      border-radius: 0.125rem;
    }
  }
`;
