import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default ({ initialClientRender }) => (
  <StaticQuery
    query={graphql`
      {
        playerPics: allFile(
          filter: { relativePath: { regex: "/Home/player_pics/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 198, maxHeight: 300, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={({ playerPics }) => (
      <PlayerPics>
        {playerPics.edges.map(({ node: playerPic }, i) => (
          <PlayerPic
            i={i}
            initialClientRender={initialClientRender}
            key={playerPic.name}
            fluid={playerPic.childImageSharp.fluid}
            alt={playerPic.name.match(/[A-Za-z]+/g).join(' ')}
          />
        ))}
      </PlayerPics>
    )}
  />
);

const PlayerPics = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 2.5px;
  height: 39.130434782608695vh;
  max-height: 300px;
  max-width: 76.25rem;
  width: 100%;
  z-index: 1;

  @media screen and (min-width: 550px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 5px;
    height: calc(100vh / 3);
  }

  @media screen and (min-width: 1100px) {
    grid-gap: 6.5px;
  }

  @media screen and (min-width: 1220px) {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const mountAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const PlayerPic = styled(Img)`
  ${({ initialClientRender }) =>
    initialClientRender &&
    css`
      opacity: 0;
      transform: translate3d(0, -100%, 0);
      animation: ${mountAnimation} 350ms ${({ i }) => 350 + (i * 140)}ms ease-in-out forwards;
    `};

  @media screen and (max-width: 28.063em) {
    &:nth-of-type(1) img,
    &:nth-of-type(2) img,
    &:nth-of-type(5) img {
      object-position: center center !important;
    }

    &:nth-of-type(3) img,
    &:nth-of-type(4) img,
    &:nth-of-type(6) img {
      object-position: top center !important;
    }
  }

  @media screen and (min-width: 28.125em) {
    img {
      object-position: top center !important;
    }
  }
`;
