import slugify from 'slugify';

function getSlugifiedGameTitle(gameTitle) {
  return slugify(gameTitle, {
    remove: /[*+~.,()'"!:@]/g,
    lower: true,
    strict: true
  }).replace(/-and/g, '');
}

export default getSlugifiedGameTitle;
