import React from 'react';
import Layout from './src/components/Shared/Layout';
import { GameDataProvider } from './src/context/GameDataContext';

export const wrapPageElement = ({ props }) => {
  const { component: PageComponent, page } = props.pageResources;
  return page.path === '/ie-not-supported/' ? <PageComponent {...props} /> : (
    <Layout {...props}>
      <PageComponent {...props} />
    </Layout>
  );
};

export const wrapRootElement = ({ element }) => <GameDataProvider>{element}</GameDataProvider>;
