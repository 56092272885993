import * as React from 'react';

function SvgXsAndOs1(props) {
  return (
    <svg viewBox="0 0 343 255" fill="none" {...props}>
      <path
        d="M180.32 254.274c-16.894 0-30.647-13.753-30.647-30.647s13.753-30.647 30.647-30.647 30.647 13.753 30.647 30.647-13.753 30.647-30.647 30.647zm0-42.445c-6.493 0-11.798 5.306-11.798 11.798 0 6.493 5.305 11.798 11.798 11.798 6.492 0 11.798-5.305 11.798-11.798 0-6.562-5.306-11.798-11.798-11.798zM192.15 70.754c-10.681 0-19.337-8.656-19.337-19.337s8.656-19.338 19.337-19.338c10.682 0 19.338 8.657 19.338 19.338 0 10.68-8.726 19.337-19.338 19.337zm0-26.737c-4.118 0-7.469 3.35-7.469 7.47 0 4.118 3.351 7.47 7.469 7.47 4.119 0 7.47-3.352 7.47-7.47-.07-4.12-3.42-7.47-7.47-7.47zM172.587 20.245c-5.585 0-10.122-4.538-10.122-10.122C162.465 4.538 167.002 0 172.587 0s10.123 4.538 10.123 10.123c0 5.585-4.538 10.122-10.123 10.122zm0-14.032a3.903 3.903 0 00-3.909 3.91 3.903 3.903 0 003.909 3.909 3.904 3.904 0 003.91-3.91 3.904 3.904 0 00-3.91-3.909zM139.474 200.302c-5.585 0-10.123-4.538-10.123-10.123 0-5.585 4.538-10.122 10.123-10.122 5.584 0 10.122 4.537 10.122 10.122s-4.538 10.123-10.122 10.123zm0-14.032a3.903 3.903 0 00-3.91 3.909 3.904 3.904 0 003.91 3.91 3.903 3.903 0 003.909-3.91 3.902 3.902 0 00-3.909-3.909zM72.671 199.891l-6.98-6.911-7.68 7.609-7.61-7.609-6.98 6.911 7.679 7.68-7.68 7.679 6.982 6.911 7.61-7.609 7.678 7.609 6.981-6.911-7.679-7.679 7.68-7.68zM286.681 65.074L273.347 51.74l-14.591 14.59-14.66-14.59-13.334 13.334 14.66 14.59-14.66 14.591 13.334 13.334 14.66-14.59 14.591 14.59 13.334-13.334-14.591-14.59 14.591-14.59zM195.461 128.758l-16.819 22.229 19.846 15.037 16.818-22.228-19.845-15.038zM187.058 83.797l-14.946 19.754 22.321 16.913 14.947-19.754-22.322-16.913z"
        fill="#C1BEAC"
      />
      <path
        d="M168.465 108.251l-24.369-18.55-16.781 22.271 24.369 18.552-18.348 24.473 22.27 16.954 18.348-24.474 16.782-22.272-22.271-16.954z"
        fill="#009245"
        fillOpacity={0.5}
      />
      <path
        d="M343 165.43l-20.925-32.636-35.763 22.92L263.405 120l-32.618 20.868 22.976 35.782L218 199.57l20.925 32.636 35.694-22.92L297.595 245l32.549-20.868-22.907-35.782L343 165.43zM23.387 212.378l-6.423-4.747-5.166 6.981-7.05-5.166L0 215.799l6.981 5.236-5.236 6.981 6.423 4.817 5.236-7.051 6.98 5.235 4.748-6.422-6.981-5.166 5.236-7.051zM129.615 217.368l-7.679-11.938-13.054 8.377-8.448-13.054-11.937 7.679 8.447 13.055-13.125 8.377 7.68 11.938 13.054-8.378L113 246.549l11.938-7.68-8.447-13.124 13.124-8.377z"
        fill="#C1BEAC"
      />
    </svg>
  );
}

export default SvgXsAndOs1;
