import * as React from 'react';

function SvgXsAndOs3(props) {
  return (
    <svg viewBox="0 0 266 166" fill="none" {...props}>
      <path
        d="M25.271 50.543c13.943 0 25.272-11.329 25.272-25.272C50.543 11.328 39.214 0 25.27 0 11.328 0 0 11.329 0 25.271c0 13.943 11.329 25.272 25.271 25.272zm0-35.032a9.744 9.744 0 019.76 9.76 9.744 9.744 0 01-9.76 9.76 9.744 9.744 0 01-9.76-9.76 9.744 9.744 0 019.76-9.76zM192.045 49.519l17.428-17.254 19.172 18.997 18.997-18.997 17.428 17.254-19.171 19.171 19.171 19.172-17.428 17.254-18.997-18.997-19.172 18.997-17.428-17.254 19.171-19.172-19.171-19.171zM49.883 93.149l19.17-29.803 32.592 20.914 21.089-32.591 29.802 19.171-21.088 32.592 32.766 20.914-19.172 29.803-32.591-20.915L91.362 166 61.56 146.829l21.088-32.766-32.765-20.914z"
        fill="#C1BEAC"
        fillOpacity={0.2}
      />
    </svg>
  );
}

export default SvgXsAndOs3;
