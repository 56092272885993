import React from 'react';
import styled from 'styled-components';

const Video = ({ videoCode }) => (
  <Wrapper>
    <iframe
      src={`https://player.vimeo.com/video/${videoCode}?transparent=true`}
      frameBorder="0"
      title="Campaign X"
      allow="fullscreen"
      allowFullScreen
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }}
    />
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`;

export default Video;
