import getSlugifiedGameTitle from 'utils/getSlugifiedGameTitle';

/* On launch of a new game the players will land on the website via a url that contains the name of
their game in a query string, e.g. 'https://www.campaign-x.com/?game=pfizer-hbu'. We check for this so
that we know when to show the Welcome video, whether the user is logged in already or not. This
ensures the new visitor sees the welcome video no matter what, so they're not left sitting there
wondering what to do if they haven't logged in yet. */
function checkQueryStringForNewGameLaunch(queryStringValue, allGames) {
  if (
    allGames.edges.filter(({ node: game }) => {
      const slugifiedGameTitle = getSlugifiedGameTitle(game.childMarkdownRemark.frontmatter.title);
      // console.log(`slug: ${slugifiedGameTitle}`);
      return queryStringValue !== 'demo' && slugifiedGameTitle === queryStringValue;
    }).length
  ) {
    return true;
  }
  return false;
}

export default checkQueryStringForNewGameLaunch;
