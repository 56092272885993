import React from 'react';
import styled from 'styled-components';
import XsAndOs5 from 'images/Levels/XsAndOs5';

export default ({ level, benefits, scoring }) => (
  <Wrapper>
    <h3>Benefits</h3>
    <Benefits>
      <div>
        <div>For Players</div>
        <div
          dangerouslySetInnerHTML={{
            __html: benefits.forPlayers
          }}
        />
      </div>
      <div>
        <div>For The Campaign</div>
        <div
          dangerouslySetInnerHTML={{
            __html: benefits.forTheCampaign
          }}
        />
      </div>
    </Benefits>
    <PointsAvailable>
      <h3>{`Points Available For Level ${level}`}</h3>
      <div>
        <div>
          <h3>Challenge Points</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: `${scoring.challenge.points === 0 ? 'Zero' : scoring.challenge.points} ${scoring.challenge.detail}`
            }}
          />
        </div>
        <div>
          <h3>Team Game Points</h3>
          <p dangerouslySetInnerHTML={{ __html: `${level === 8 ? '' :scoring.teamGame.points === 0 ? 'Zero' : scoring.teamGame.points} ${scoring.teamGame.detail}` }} />
        </div>
      </div>
      <TotalPoints>
        <h3>Total Points Available For This Level</h3>
        <h3>{`${scoring.challenge.points + scoring.teamGame.points} Points`}</h3>
      </TotalPoints>
      <StyledXsAndOs5 />
    </PointsAvailable>
  </Wrapper>
);

const Wrapper = styled.section`
  > h3 {
    color: var(--cx-dark-green);
    font-size: 2rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;

    &:nth-of-type(1) {
      margin: 2.8em auto 1.063em;
    }
  }
`;

const Benefits = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    border: 0.063em solid var(--cx-dark-green);
    border-radius: 0.125em;
    max-width: 36.25em;
    width: 100%;

    &:nth-of-type(1) {
      margin-right: 0.625em;
    }

    &:nth-of-type(2) {
      margin-left: 0.625em;
    }

    > div:nth-of-type(1) {
      align-items: center;
      background-color: var(--cx-dark-green);
      color: #fff;
      display: flex;
      font-size: 1.5rem;
      font-weight: 600;
      height: 3.083em;
      justify-content: center;
    }

    > div:nth-of-type(2) {
      font-size: 1.5rem;
      padding: 0.833em;
      line-height: 1.333em;
    }
  }
`;

const PointsAvailable = styled.div`
  background-color: var(--cx-beige);
  margin-top: 1.25em;
  margin-bottom: 5.188em;
  padding-top: 3.2em;
  padding-bottom: 1.25em;
  position: relative;

  > h3 {
    color: var(--cx-dark-green);
    font-size: 2rem;
    margin-bottom: 1.2em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
  }

  > div:first-of-type {
    max-width: 73.75rem;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;

    > div {
      align-items: center;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 2.5em;

      h3 {
        font-size: 2rem;
        margin-bottom: 0.025em;
        text-align: center;
      }

      p {
        font-size: 1.5rem;
        text-align: center;
        line-height: 1.333em;
      }
    }

    > div:nth-of-type(1) {
      margin-left: 1.25em;
      margin-right: 0.625em;
    }

    > div:nth-of-type(2) {
      margin-left: 0.625em;
      margin-right: 1.25em;
    }
  }
`;

const TotalPoints = styled.div`
  align-items: center;
  background-color: var(--cx-dark-green);
  display: flex;
  flex-direction: column;
  height: 10.125em;
  justify-content: center;
  margin: 1.25em auto 0;
  width: 38em;
  padding-top: 0.5em;

  h3 {
    text-align: center;
  }

  h3:nth-of-type(1) {
    color: #000;
    font-size: 2rem;
    line-height: 1.5em;
  }

  h3:nth-of-type(2) {
    color: #fff;
    font-size: 3rem;
    line-height: 1.25em;
  }
`;

const StyledXsAndOs5 = styled(XsAndOs5)`
  position: absolute;
  right: -4.938em;
  bottom: -2.85em;
  width: 21.438em;
`;
