import * as React from 'react';

function SvgXsAndOs2(props) {
  return (
    <svg viewBox="0 0 266 318" fill="none" {...props}>
      <path
        d="M177.766 116.809c-10.353 9.274-11.181 25.271-1.847 35.686 9.335 10.414 25.332 11.341 35.686 2.066 10.354-9.274 11.182-25.271 1.847-35.685-9.335-10.414-25.332-11.341-35.686-2.067zm23.454 26.166c-4.012 3.594-10.165 3.238-13.782-.798-3.617-4.035-3.299-10.188.713-13.782 4.012-3.594 10.165-3.237 13.782.798 3.617 4.036 3.299 10.189-.713 13.782zM121.325 94.724l-9.888 22.443-24.802-10.644-10.708 24.64-22.541-9.662 10.806-24.866L39.327 85.83l9.888-22.443 24.64 10.708 10.87-24.704 22.541 9.662-10.807 24.866 24.866 10.806zM155.098 176.318l15.29 31.969-34.916 16.747 16.655 35.064-31.968 15.29-16.655-35.065-35.008 16.896-15.29-31.968 34.916-16.748-16.804-35.155 31.969-15.29 16.804 35.156 35.007-16.896z"
        fill="#C1BEAC"
        fillOpacity={0.2}
      />
    </svg>
  );
}

export default SvgXsAndOs2;
