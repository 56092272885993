import * as React from 'react';

function SvgXsAndOs6(props) {
  return (
    <svg viewBox="0 0 235 174" fill="none" {...props}>
      <path
        d="M123.393 174c-11.561 0-20.972-9.411-20.972-20.972 0-11.561 9.411-20.972 20.972-20.972 11.561 0 20.972 9.411 20.972 20.972 0 11.561-9.411 20.972-20.972 20.972zm0-29.045c-4.443 0-8.074 3.63-8.074 8.073 0 4.443 3.631 8.074 8.074 8.074s8.073-3.631 8.073-8.074c0-4.49-3.63-8.073-8.073-8.073zM131.489 48.417c-7.309 0-13.233-5.924-13.233-13.233s5.924-13.232 13.233-13.232 13.233 5.923 13.233 13.232c0 7.31-5.972 13.233-13.233 13.233zm0-18.296a5.117 5.117 0 00-5.112 5.111 5.117 5.117 0 005.112 5.112 5.117 5.117 0 005.111-5.112c-.047-2.818-2.34-5.111-5.111-5.111zM118.102 13.854a6.932 6.932 0 01-6.927-6.927A6.932 6.932 0 01118.102 0a6.932 6.932 0 016.927 6.927 6.932 6.932 0 01-6.927 6.927zm0-9.602a2.671 2.671 0 00-2.676 2.675 2.671 2.671 0 002.676 2.675 2.67 2.67 0 002.675-2.675 2.67 2.67 0 00-2.675-2.675zM95.442 137.067a6.932 6.932 0 01-6.927-6.927 6.932 6.932 0 016.927-6.927 6.932 6.932 0 016.927 6.927 6.932 6.932 0 01-6.927 6.927zm0-9.602a2.67 2.67 0 00-2.675 2.675 2.67 2.67 0 002.675 2.675 2.67 2.67 0 002.675-2.675 2.67 2.67 0 00-2.675-2.675zM49.73 136.786l-4.778-4.73-5.255 5.208-5.207-5.208-4.777 4.73 5.255 5.255-5.255 5.255 4.777 4.729 5.207-5.207 5.255 5.207 4.777-4.729-5.255-5.255 5.255-5.255zM196.176 44.53l-9.125-9.124-9.984 9.984-10.032-9.984-9.124 9.124 10.032 9.985-10.032 9.984 9.124 9.124 10.032-9.984 9.984 9.984 9.125-9.124-9.985-9.984 9.985-9.985zM133.897 88.11l-11.653 15.359 13.436 10.141 11.653-15.358-13.436-10.142zM128.145 57.342l-10.37 13.669 15.133 11.423 10.37-13.669-15.133-11.423z"
        fill="#C1BEAC"
      />
      <path
        d="M115.507 74.105L98.781 61.683 86.993 76.96l16.726 12.423-12.89 16.788 15.285 11.352 12.89-16.788 11.789-15.278-15.286-11.353z"
        fill="#009245"
        fillOpacity={0.5}
      />
      <path
        d="M234.715 113.204l-14.318-22.333-24.473 15.684-15.676-24.439-22.32 14.28 15.722 24.486-24.472 15.684 14.318 22.333 24.426-15.685 15.723 24.44 22.273-14.28-15.675-24.486 24.472-15.684zM16.003 145.33l-4.395-3.248-3.535 4.777-4.825-3.535L0 147.671l4.777 3.583-3.583 4.777 4.395 3.296 3.583-4.824 4.777 3.582 3.249-4.395-4.777-3.535 3.582-4.825zM88.696 148.745l-5.255-8.169-8.933 5.733-5.78-8.934-8.17 5.255 5.78 8.933-8.98 5.733 5.255 8.169 8.933-5.733 5.78 8.981 8.17-5.254-5.781-8.981 8.98-5.733z"
        fill="#C1BEAC"
      />
    </svg>
  );
}

export default SvgXsAndOs6;
