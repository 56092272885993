import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import crossmark from 'images/Shared/crossmark.svg';
import { motion, AnimatePresence } from 'framer-motion';

export default ({ user, gameTitle, toggleContactUsModal }) => {
  const [name, setName] = useState((user && user.name) || '');
  const [email, setEmail] = useState((user && user.email) || '');
  const [game, setGame] = useState(gameTitle || '');
  const [message, setMessage] = useState('');
  const [botField, setBotfield] = useState('');
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  useEffect(() => {
    setName((user && user.name) || '');
    setEmail((user && user.email) || '');
  }, [user]);

  useEffect(() => {
    setGame(gameTitle || '');
  }, [gameTitle]);

  const encodeData = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    return formData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsMessageSending(true);

    const form = e.target;

    const options = {
      method: 'POST',
      body: encodeData({
        'form-name': form.getAttribute('name'),
        name,
        email,
        game,
        message
      })
    };

    fetch('/', options)
      .then((response) => {
        if (response.ok) {
          setTimeout(() => {
            setBotfield('');
            setIsMessageSending(false);
            setIsMessageSent(true);
          }, 1500);
        }
      })
      .catch((error) => console.error(error));
  };

  const checkIfMessageAlreadySent = () => {
    if (isMessageSent) {
      setIsMessageSent(false);
    }
  };

  return (
    <Wrapper>
      <CloseModal
        onClick={() => {
          toggleContactUsModal();
          setIsMessageSent(false);
          setMessage('');
        }}>
        <img src={crossmark} alt="crossmark" />
      </CloseModal>
      <H3>Contact Us</H3>
      <Form
        name="Contact Us"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}>
        <Input type="hidden" name="form-name" value="Contact Us" />
        <div hidden>
          <Label>
            Don’t fill this out:
            <Input
              name="bot-field"
              value={botField}
              onChange={(e) => {
                checkIfMessageAlreadySent();
                setBotfield(e.target.value);
              }}
            />
          </Label>
        </div>
        <Label htmlFor="name">Name</Label>
        <Input
          type="name"
          name="name"
          id="name"
          value={name}
          onChange={(e) => {
            checkIfMessageAlreadySent();
            setName(e.target.value);
          }}
          required
        />
        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => {
            checkIfMessageAlreadySent();
            setEmail(e.target.value);
          }}
          required
        />
        <Label htmlFor="game" style={{ height: '1px', width: '1px', opacity: 0 }}>
          Game
        </Label>
        <Input
          type="hidden"
          name="game"
          id="game"
          value={game}
          onChange={() => checkIfMessageAlreadySent()}
        />
        <Label htmlFor="message" style={{ marginBottom: '0.5rem' }}>
          Message
        </Label>
        <TextArea
          as="textarea"
          type="textarea"
          name="message"
          id="message"
          cols="30"
          rows="10"
          value={message}
          onChange={(e) => {
            checkIfMessageAlreadySent();
            setMessage(e.target.value);
          }}
          required
        />
        <SubmitWrapper isMessageSent={isMessageSent}>
          <AnimatePresence>
            {isMessageSent ? (
              <ThankYou initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                Thank you!
                <br />
                We&apos;ll be in touch shortly.
              </ThankYou>
            ) : (
              <Submit
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                isMessageSending={isMessageSending}
                type="submit">
                {isMessageSending ? 'Sending' : 'Submit'}
              </Submit>
            )}
          </AnimatePresence>
        </SubmitWrapper>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 54px 20px 25px;
`;

const H3 = styled.h3`
  font-size: 2rem;
  margin-bottom: 0.825em;
  text-align: center;
`;

const CloseModal = styled.button`
  background-color: var(--cx-dark-green);
  width: 2.875rem;
  height: 2.875rem;
  cursor: pointer;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    background-color: #007737;
    transition: background-color 150ms ease-in-out;
  }

  &:not(:hover) {
    background-color: var(--cx-dark-green);
    transition: background-color 150ms ease-in-out;
  }
`;

const Label = styled.label``;

const Input = styled.input`
  border-style: none;
  border-bottom-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #000;
  font-family: proxima-nova, sans-serif;
  height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0 auto 3.1em;
  outline: none;
  width: 100%;

  &::placeholder {
    color: var(--ax-grey);
    font-size: 0.875rem;
    font-weight: 300;
    font-style: italic;
  }
`;

const TextArea = styled(Input)`
  border: 1px solid #000;
  height: 223px;
  resize: none;
  padding: 0.75em 0.5em;
  margin-bottom: 1.714em;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const SubmitWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ThankYou = styled(motion.span)`
  font-size: 1rem;
  font-style: italic;
  height: 2.875em;
  position: relative;
  text-align: center;
  top: 0.156em;
  font-weight: 500;
`;

const Submit = styled.button`
  background-color: var(--cx-dark-green);
  border-radius: 0.125em;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  height: 2.875em;
  line-height: 1.214em;
  outline: none;
  color: #fff;
  text-transform: uppercase;
  width: 10.375em;

  &:hover {
    background-color: #007737;
    transition: background-color 150ms ease-in-out;
  }

  &:not(:hover) {
    background-color: var(--cx-dark-green);
    transition: background-color 150ms ease-in-out;
  }

  ${({ isMessageSending }) =>
    isMessageSending &&
    css`
      &:after {
        content: ' .';
        margin-left: -3px;
        animation: dots 1s steps(5, end) infinite;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
        }
      }
    `}
`;
