import * as React from 'react';

function SvgXsAndOs5(props) {
  return (
    <svg viewBox="0 0 343 243" fill="none" {...props}>
      <path
        d="M180.32 242.274c-16.894 0-30.647-13.753-30.647-30.647s13.753-30.647 30.647-30.647 30.647 13.753 30.647 30.647-13.753 30.647-30.647 30.647zm0-42.445c-6.493 0-11.798 5.306-11.798 11.798s5.305 11.798 11.798 11.798c6.492 0 11.798-5.306 11.798-11.798 0-6.562-5.306-11.798-11.798-11.798zM192.151 58.754c-10.681 0-19.338-8.656-19.338-19.337s8.657-19.338 19.338-19.338c10.681 0 19.337 8.657 19.337 19.338 0 10.68-8.726 19.337-19.337 19.337zm0-26.737c-4.119 0-7.47 3.35-7.47 7.47 0 4.118 3.351 7.47 7.47 7.47 4.118 0 7.469-3.352 7.469-7.47-.069-4.12-3.42-7.47-7.469-7.47zM264.123 20.245c-5.585 0-10.123-4.538-10.123-10.122C254 4.538 258.538 0 264.123 0c5.584 0 10.122 4.538 10.122 10.123 0 5.585-4.538 10.122-10.122 10.122zm0-14.032a3.903 3.903 0 00-3.91 3.91 3.903 3.903 0 003.91 3.909 3.903 3.903 0 003.909-3.91 3.903 3.903 0 00-3.909-3.909zM139.474 188.302c-5.585 0-10.123-4.538-10.123-10.123 0-5.585 4.538-10.122 10.123-10.122 5.585 0 10.122 4.537 10.122 10.122s-4.537 10.123-10.122 10.123zm0-14.032a3.903 3.903 0 00-3.91 3.909 3.904 3.904 0 003.91 3.91 3.903 3.903 0 003.909-3.91 3.902 3.902 0 00-3.909-3.909zM72.671 187.891l-6.98-6.911-7.68 7.609-7.61-7.609-6.98 6.911 7.679 7.68-7.68 7.679 6.982 6.911 7.61-7.609 7.678 7.609 6.981-6.911-7.679-7.679 7.68-7.68zM286.681 53.074L273.347 39.74l-14.591 14.59-14.66-14.59-13.334 13.334 14.66 14.59-14.66 14.591 13.334 13.334L258.756 81l14.591 14.59 13.334-13.334-14.591-14.59 14.591-14.59zM193.914 116.757l-15.272 21.246 21.394 16.021 15.272-21.247-21.394-16.02zM185.558 71.798l-13.446 18.756 23.819 17.909 13.447-18.757-23.82-17.908z"
        fill="#C1BEAC"
      />
      <path
        d="M166.846 96.01l-24.008-20.942-14.594 21.943 24.008 20.941-15.95 24.118 21.94 19.138 15.95-24.118 14.594-21.943-21.94-19.138z"
        fill="#009245"
        fillOpacity={0.5}
      />
      <path
        d="M343 153.43l-20.925-32.636-35.763 22.92L263.405 108l-32.618 20.868 22.976 35.782L218 187.57l20.925 32.636 35.694-22.92L297.595 233l32.549-20.868-22.907-35.782L343 153.43zM23.387 200.378l-6.423-4.747-5.166 6.981-7.05-5.166L0 203.799l6.981 5.235-5.236 6.982 6.423 4.817 5.236-7.051 6.98 5.235 4.748-6.422-6.981-5.166 5.236-7.051zM129.615 205.368l-7.679-11.938-13.055 8.377-8.447-13.054-11.937 7.679 8.447 13.055-13.125 8.377 7.68 11.938 13.054-8.378L113 234.549l11.938-7.68-8.447-13.124 13.124-8.377z"
        fill="#C1BEAC"
      />
    </svg>
  );
}

export default SvgXsAndOs5;
