import * as React from 'react';

function SvgSmallQuotes(props) {
  return (
    <svg viewBox="0 0 78 62" fill="none" {...props}>
      <path
        d="M22.413 1.61l-.292-.234-.307.214C9.002 10.507.5 24.423.5 40.03.5 53.64 9.299 61.5 19.232 61.5c9.086 0 16.683-7.626 16.683-16.74 0-9.1-6.354-15.302-14.43-15.302-.837 0-1.732.053-2.501.16a9.57 9.57 0 00-.852.153c1.035-3.343 3.193-6.914 5.862-10.105 2.915-3.484 6.4-6.47 9.608-8.18l.669-.356-.591-.475L22.413 1.61zm41.581-.003l-.29-.23-.305.213c-12.816 8.92-21.11 22.837-21.11 38.441 0 6.788 2.136 12.158 5.537 15.835 3.4 3.677 8.041 5.634 12.99 5.634 9.296 0 16.684-7.63 16.684-16.74 0-9.095-6.349-15.302-14.225-15.302-.838 0-1.732.053-2.501.16-.313.042-.61.095-.878.159.954-3.34 3.113-6.913 5.81-10.11 2.939-3.483 6.476-6.47 9.686-8.18l.675-.36-.601-.474-11.472-9.046z"
        stroke="#000"
        strokeOpacity={0.35}
      />
    </svg>
  );
}

export default SvgSmallQuotes;
