import React from 'react';
import styled from 'styled-components';
import XsAndOs3 from 'images/Home/XsAndOs3';
import XsAndOs4 from 'images/Home/XsAndOs4';

const CompeteAndCollaborate = ({ viewportWidth }) => (
  <Wrapper>
    {viewportWidth >= 768 && (
      <>
        <StyledXsAndOs3 />
        <StyledXsAndOs4 />
      </>
    )}
    <InnerWrapper>
      <h3>Compete And Collaborate</h3>
      <h3>for best in class results</h3>
      <p>
        Throughout the journey we consolidate the best contributions to date from all teams into a
        single team view which forms the bedrock of the next challenge. With this mix of competition
        and collaboration, we ensure that the campaign is continually benefiting from the best in
        class ideas at each level.
      </p>
    </InnerWrapper>
  </Wrapper>
);

const InnerWrapper = styled.div`
  max-width: 90em;
  margin: 0 2.66%;

  @media screen and (min-width: 28.125em) {
    margin: 0 9.1%;
  }
`;

const Wrapper = styled.section`
  position: relative;

  h3 {
    color: var(--cx-dark-green);
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width: 48rem) {
      font-size: 2rem;
    }

    &:nth-of-type(1) {
      padding: 0.7em 0 0.25em;

      @media screen and (min-width: 48rem) {
        padding: 0.7em 0 0.05em;
      }
    }

    &:nth-of-type(2) {
      color: #000;
      text-transform: lowercase;
    }

    &:last-of-type {
      letter-spacing: unset;
      margin: 0.5em auto 3.2em;
      text-transform: unset;

      @media screen and (min-width: 48rem) {
        margin: 0.1625em auto 3.65em;
      }
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.333em;
    margin: 1.8em auto 3.45em;

    @media screen and (min-width: 48rem) {
      margin: 2.43em auto 3.917em;
      max-width: 39.167em;
      font-size: 1.5rem;
    }
  }

  h2 {
    color: #000;
    font-size: 2rem;
    text-align: center;

    @media screen and (min-width: 48rem) {
      font-size: 3rem;
    }
  }
`;

const StyledXsAndOs3 = styled(XsAndOs3)`
  left: 3.33%;
  position: absolute;
  top: 0;
  width: 16.563em;
`;

const StyledXsAndOs4 = styled(XsAndOs4)`
  position: absolute;
  right: 0;
  top: -3.575em;
  width: 33.875em;
`;

export default CompeteAndCollaborate;
