import React, { PureComponent } from 'react';
import { navigate } from 'gatsby';
import { GameDataContext } from 'context';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { scroller } from 'react-scroll';
import checkQueryStringForNewGameLaunch from 'utils/checkQueryStringForNewGameLaunch';
import {
  ContactUsButton,
  ContactUsForm,
  Footer,
  GameSelect,
  Header,
  LevelCompleted,
  RenderWhenReady,
  Scoreboard
} from 'components';
import { GlobalStyle } from 'shared_styles';
import netlifyIdentity from 'netlify-identity-widget';
import crossmark from 'images/Shared/crossmark.svg';
import queryString from 'query-string';
import 'rodal/lib/rodal.css';
import Rodal from 'rodal';

class Layout extends PureComponent {
  state = {
    completedLevel: null,
    game: null,
    initialClientRender: true,
    isContactUsModalOpen: false,
    isDemoGame: queryString.parse(this.props.location.search).game === 'demo',
    isLevelCompletedModalOpen: false,
    isScoreboardOpen: false,
    isWelcomeVideoVisible: false,
    selectedLevel: null,
    showMultipleGamesModal: false,
    showVisitorNotAuthorisedToPlayModal: false,
    siteVisitorNotAuthorisedToPlay: false,
    showWelcomeToDemoGameModal: false,
    user: null,
    usersMultipleGameData: null
  };

  handleWindowResize = throttle(() => {
    const viewportWidth = window.innerWidth;
    this.setState((prevState) => {
      if (viewportWidth !== undefined && viewportWidth !== prevState.viewportWidth) {
        return {
          viewportWidth
        };
      }
    });
  }, 100);

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);

    if (
      checkQueryStringForNewGameLaunch(
        queryString.parse(this.props.location.search).game,
        this.context.gameData
      )
    ) {
      this.setState({
        isWelcomeVideoVisible: true
      });
      setTimeout(() => this.scrollToAnchor('welcome'), 500);
    }

    netlifyIdentity.init();

    const userFromLocalStorage = netlifyIdentity.currentUser();

    if (userFromLocalStorage) {
      const usersGameData = this.getUsersGameData(userFromLocalStorage.email);
      this.setUser(userFromLocalStorage);
      this.handleUsersGameData(usersGameData);
    }

    netlifyIdentity.on('login', (user) => {

      this.setUser(user);

      const usersGameData = this.getUsersGameData(user.email);

      this.handleUsersGameData(usersGameData);

      netlifyIdentity.close();
    });

    netlifyIdentity.on('logout', () => {
      netlifyIdentity.close();
      this.setUser(null);
      this.setGame(null);
      this.updateLevel(null);
      if(localStorage.getItem('isDemoGame')) {
        localStorage.removeItem('isDemoGame')
      }
      window.location.reload();
    });

    if (this.props.location.pathname.includes('level') && typeof Storage !== 'undefined') {
      const cachedSelectedLevel = parseInt(localStorage.getItem('cachedSelectedLevel'), 10);
      if (cachedSelectedLevel) {
        this.setState({
          selectedLevel: cachedSelectedLevel
        });
      }
    }

    netlifyIdentity.on('open', () => {
      const iframe = document.getElementById('netlify-identity-widget').contentDocument;
      const modal = iframe && iframe.getElementsByClassName('modalContent')[0];

      if (modal) {
        modal.style.marginTop = 0;
      }

      const codedByNetlify = iframe && iframe.getElementsByClassName('callOut')[0];

      if (codedByNetlify) {
        codedByNetlify.style.display = 'none';
      }
    });

    setTimeout(() => {
      this.setState({
        initialClientRender: false
      });
    }, 2000);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleUsersGameData = (usersGameData) => {
    if (usersGameData.length === 1) {
      const game = usersGameData[0].node.childMarkdownRemark.frontmatter;
      this.setGame(game);
    } else if (usersGameData.length > 1) {
      this.setState({
        usersMultipleGameData: usersGameData
      });
      this.toggleMultipleGamesModal();
    } else if (this.state.isDemoGame) {
      /* User has not given us their email address, so we either check if the site is in 'demo' mode, or else we kick them out. */
      const { edges: games } = this.context.gameData;
      const demoGame = games.filter(
        ({ node: game }) => game.childMarkdownRemark.frontmatter.title === 'demo'
      )[0].node.childMarkdownRemark.frontmatter;
      this.setGame(demoGame);
      this.toggleWelcomeToDemoGameModal();
      if (typeof Storage !== 'undefined') {
        localStorage.setItem('isDemoGame', 'true');
      }
    } else if (localStorage.getItem('isDemoGame') !== 'true') {
      this.setState({
        siteVisitorNotAuthorisedToPlay: true
      });
      this.toggleVisitorNotAuthorisedToPlayModal();
    }
  };

  setUser = (user) => {
    if (user) {
      this.setState({
        user: {
          name: user.user_metadata.full_name,
          email: user.email
        }
      });
    } else {
      this.setState({
        user: null
      });
    }
  };

  setGame = (game) => {
    if (game) {
      this.setState({
        game
      });
      if (
        this.props.location.pathname === '/' &&
        game.title !== 'demo' &&
        game.highestActiveLevel === 1 &&
        !game.hideLiveStreamAfterLaunch
      ) {
        setTimeout(() => this.scrollToAnchor('welcome'), 500);
      }
    } else {
      this.setState({
        game: null,
        usersMultipleGameData: null
      });
    }
  };

  getUsersGameData = (usersEmail) => {
    const { edges: games } = this.context.gameData;

    const usersGameData = games.filter(({ node: game }) => {
      const { teams } = game.childMarkdownRemark.frontmatter;

      if (teams) {
        const allPlayersInThisGame = teams.reduce((arr, team) => {
          arr.push(...team.players);
          return arr;
        }, []);

        return allPlayersInThisGame
          .map((player) => player && player.toLowerCase())
          .includes(usersEmail.toLowerCase());
      }

      return false;
    });

    return usersGameData;
  };

  handleGameSelection = (game) => {
    this.setGame(game);
    this.toggleMultipleGamesModal();
    this.updateLevel(null);

    if (this.props.location.pathname !== '/') {
      navigate('/');
    }
  };

  updateLevel = (level) => {
    this.setState((prevState) => {
      if (prevState.selectedLevel !== level) {
        if (typeof Storage !== 'undefined') {
          localStorage.setItem('cachedSelectedLevel', level);
        }
        return {
          selectedLevel: level
        };
      }
    });
  };

  scrollToAnchor = (anchor, offset = 0) => {
    scroller.scrollTo(anchor, {
      duration: 1000,
      delay: 0,
      offset,
      smooth: 'easeInOutQuint',
      ignoreCancelEvents: true
    });
  };

  toggleWelcomeToDemoGameModal = () => {
    this.setState((prevState) => ({
      showWelcomeToDemoGameModal: !prevState.showWelcomeToDemoGameModal
    }));
  };

  toggleMultipleGamesModal = () => {
    this.setState((prevState) => ({
      showMultipleGamesModal: !prevState.showMultipleGamesModal
    }));
  };

  toggleVisitorNotAuthorisedToPlayModal = () => {
    this.setState((prevState) => ({
      showVisitorNotAuthorisedToPlayModal: !prevState.showVisitorNotAuthorisedToPlayModal
    }));
  };

  toggleLevelCompleteModal = (completedLevel) => {
    this.setState((prevState) => ({
      isLevelCompletedModalOpen: !prevState.isLevelCompletedModalOpen,
      completedLevel
    }));
  };

  toggleScoreboard = () => {
    this.setState((prevState) => ({
      isScoreboardOpen: !prevState.isScoreboardOpen
    }));
  };

  toggleContactUsModal = () => {
    this.setState((prevState) => ({
      isContactUsModalOpen: !prevState.isContactUsModalOpen
    }));
  };

  render() {
    const { children } = this.props;
    const {
      completedLevel,
      game,
      isDemoGame,
      initialClientRender,
      isLevelCompletedModalOpen,
      isScoreboardOpen,
      isWelcomeVideoVisible,
      isContactUsModalOpen,
      selectedLevel,
      showMultipleGamesModal,
      showVisitorNotAuthorisedToPlayModal,
      showWelcomeToDemoGameModal,
      siteVisitorNotAuthorisedToPlay,
      user,
      usersMultipleGameData,
      viewportWidth
    } = this.state;

    return (
      <RenderWhenReady>
        <Wrapper>
          <GlobalStyle />
          <Header
            companyLogo={game && game.logo}
            game={game}
            highestActiveLevel={game && game.highestActiveLevel}
            isDemoGame={isDemoGame}
            isWelcomeVideoVisible={isWelcomeVideoVisible}
            netlifyIdentity={netlifyIdentity}
            selectedLevel={selectedLevel}
            siteVisitorNotAuthorisedToPlay={siteVisitorNotAuthorisedToPlay}
            toggleMultipleGamesModal={this.toggleMultipleGamesModal}
            toggleScoreboard={this.toggleScoreboard}
            updateLevel={this.updateLevel}
            user={user}
            usersMultipleGameData={usersMultipleGameData}
            viewportWidth={viewportWidth}
          />
          <Rodal
            customStyles={{
              alignItems: 'center',
              borderRadius: '0.125rem 0 0.125rem 0.125rem',
              bottom: 'auto',
              display: 'flex',
              height: 'unset',
              justifyContent: 'center',
              maxWidth: '58.750rem',
              overflow: 'hidden',
              padding: '1.25rem',
              position: 'relative',
              top: '101px',
              width: '100%'
            }}
            showCloseButton={false}
            visible={isScoreboardOpen}
            onClose={() => this.toggleScoreboard()}>
            {game && <Scoreboard game={game} toggleScoreboard={this.toggleScoreboard} />}
          </Rodal>
          <Rodal
            customStyles={{
              borderRadius: '2px 0 2px 2px',
              bottom: 'auto',
              height: 'auto',
              overflow: 'hidden',
              padding: 0,
              top: '80px',
              width: '498px'
            }}
            showCloseButton={false}
            visible={Boolean(isLevelCompletedModalOpen && completedLevel)}
            onClose={(e) => e.preventDefault()}>
            {completedLevel && (
              <LevelCompleted
                completedLevel={completedLevel}
                toggleLevelCompleteModal={this.toggleLevelCompleteModal}
                isDemoGame={isDemoGame}
              />
            )}
          </Rodal>
          <Rodal
            customStyles={{
              borderRadius: '2px 0 2px 2px',
              bottom: 'auto',
              height: 'unset',
              overflow: 'hidden',
              padding: 0,
              top: '101px',
              width: '400px'
            }}
            showCloseButton={false}
            visible={showWelcomeToDemoGameModal}
            onClose={() => this.toggleWelcomeToDemoGameModal()}>
            {showWelcomeToDemoGameModal && (
              <WelcomeToDemoGame>
                <CloseModal onClick={() => this.toggleWelcomeToDemoGameModal()}>
                  <img src={crossmark} alt="crossmark" />
                </CloseModal>
                <h2>
                  Welcome To Your
                  <br />
                  Campaign X Demo
                </h2>
                <p>
                  Please click the &apos;Levels&apos; tab at the top <br />
                  of the screen, proceed to &apos;Demo Level&apos;,
                  <br />
                  and answer our 3 quick questions.
                  <br />
                </p>
                <div
                  style={{
                    backgroundColor: '#efede4',
                    alignItems: 'center',
                    color: '#009245',
                    display: 'flex',
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    height: '3em',
                    lineHeight: '1.208em',
                    justifyContent: 'center',
                    letterSpacing: '0.1em',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    width: '100%'
                  }}>
                  Thank You!
                </div>
              </WelcomeToDemoGame>
            )}
          </Rodal>
          <Rodal
            customStyles={{
              borderRadius: '2px 0 2px 2px',
              bottom: 'auto',
              height: 'unset',
              overflow: 'hidden',
              padding: 0,
              top: '101px',
              width: '498px'
            }}
            showCloseButton={false}
            visible={Boolean(showMultipleGamesModal && usersMultipleGameData)}
            onClose={(e) => e.preventDefault()}>
            {showMultipleGamesModal && usersMultipleGameData && (
              <GameSelect
                usersMultipleGameData={usersMultipleGameData}
                handleGameSelection={this.handleGameSelection}
                toggleMultipleGamesModal={this.toggleMultipleGamesModal}
                netlifyIdentity={netlifyIdentity}
              />
            )}
          </Rodal>
          <Rodal
            customStyles={{
              borderRadius: '2px 0 2px 2px',
              overflow: 'hidden',
              width: '400px',
              height: '250px',
              bottom: 'auto',
              top: '101px',
              padding: 0
            }}
            showCloseButton={false}
            visible={siteVisitorNotAuthorisedToPlay && showVisitorNotAuthorisedToPlayModal}
            onClose={() => {
              netlifyIdentity.logout();
              this.toggleVisitorNotAuthorisedToPlayModal();
            }}>
            <NotAuthorised>
              <CloseModal
                onClick={() => {
                  netlifyIdentity.logout();
                  this.toggleVisitorNotAuthorisedToPlayModal();
                }}>
                <img src={crossmark} alt="crossmark" />
              </CloseModal>
              <h2>
                Your Email Address
                <br />
                Is Not Authorised
              </h2>
              <p>
                Please{' '}
                <a href="mailto:hello@campaign-x.com" target="__blank">
                  Contact Us
                </a>{' '}
                To Discuss
                <br />
                Signing You And Your Team Up To
                <br />
                Campaign X
              </p>
            </NotAuthorised>
          </Rodal>
          <Rodal
            customStyles={{
              borderRadius: '2px 0 2px 2px',
              bottom: 'auto',
              height: viewportWidth <= 550 ? '100vh' : 'auto',
              overflow: 'hidden',
              padding: 0,
              top: viewportWidth <= 550 ? 0 : '80px',
              maxWidth: viewportWidth <= 550 ? '100vw' : '498px',
              width: '100%',
              transition: 'height 200ms ease-in-out'
            }}
            showCloseButton={false}
            visible={Boolean(isContactUsModalOpen)}
            onClose={() => this.toggleContactUsModal()}>
            <ContactUsForm
              user={user}
              gameTitle={game && game.title}
              toggleContactUsModal={this.toggleContactUsModal}
            />
          </Rodal>
          <main>
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                scrollToAnchor: this.scrollToAnchor,
                toggleLevelCompleteModal: this.toggleLevelCompleteModal,
                user,
                game,
                isDemoGame,
                isWelcomeVideoVisible,
                initialClientRender,
                selectedLevel,
                viewportWidth
              })
            )}
          </main>
          <Footer
            isDemoGame={isDemoGame}
            netlifyIdentity={netlifyIdentity}
            toggleContactUsModal={this.toggleContactUsModal}
            toggleScoreboard={this.toggleScoreboard}
            user={user}
            viewportWidth={viewportWidth}
          />
          <ContactUsButton toggleContactUsModal={this.toggleContactUsModal} />
        </Wrapper>
      </RenderWhenReady>
    )
  }
}

Layout.contextType = GameDataContext;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 90em;
  position: relative;
  overflow-x: hidden;

  main {
  }
`;

const NotAuthorised = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  h2 {
    font-size: 2rem;
    color: #000;
    text-align: center;
    margin-bottom: 0.5em;
  }

  p {
    text-align: center;
  }

  a {
    color: var(--cx-dark-green);
    font-weight: 700;
  }
`;

const WelcomeToDemoGame = styled(NotAuthorised)`
  h2 {
    margin-top: 1.4em;
  }

  p {
    margin-bottom: 1.4em;
  }
`;

const CloseModal = styled.button`
  background-color: var(--cx-dark-green);
  width: 2.875rem;
  height: 2.875rem;
  cursor: pointer;
  border: none;
  outline-color: var(--cx-dark-green);
  position: absolute;
  top: 0;
  right: 0;
`;

export default Layout;
