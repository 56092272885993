import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import carouselIconData from 'local_data/carousel-icon-data';
import crossmark from 'images/Shared/crossmark.svg';

const LevelCompleted = ({ completedLevel, toggleLevelCompleteModal, isDemoGame }) => (
  <Wrapper isDemoGame={isDemoGame && completedLevel === 1}>
    <CloseModal onClick={() => toggleLevelCompleteModal()}>
      <img src={crossmark} alt="crossmark" />
    </CloseModal>
    <div>
      <p>Congratulations!</p>
      <p
        dangerouslySetInnerHTML={{
          __html: isDemoGame
            ? 'Your demo submission<br />is complete!'
            : `You completed Level ${completedLevel}!`
        }}
      />
      <StaticQuery
        query={graphql`
          {
            levelIcons: allFile(
              filter: {
                sourceInstanceName: { eq: "images" }
                relativeDirectory: { eq: "Shared/LevelIcons/BlackStroke" }
                name: { regex: "/_/" }
              }
              sort: { fields: [name], order: ASC }
            ) {
              edges {
                node {
                  publicURL
                }
              }
            }
          }
        `}
        render={({ levelIcons }) => (
          <Icon
            src={levelIcons.edges[completedLevel - 1].node.publicURL}
            alt={carouselIconData[completedLevel - 1].alt}
            maxWidth={carouselIconData[completedLevel - 1].maxWidth}
          />
        )}
      />
    </div>
    <NextLevel
      isDemoGame={isDemoGame}
      dangerouslySetInnerHTML={{
        __html: isDemoGame
          ? 'Get ready to have some<br />fun at the upcoming event<br />on October 7th!'
          : `Instructions for<br />level ${completedLevel + 1} coming soon`
      }}
    />
    <WantToSubmitAgain>
      Want to make changes to your submission?
      <br />
      Feel free to submit again and we&apos;ll ignore
      <br />
      your previous submission.
    </WantToSubmitAgain>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;

  > div:nth-of-type(1) {
    height: 26.5em;
    padding-top: ${({ isDemoGame }) => (isDemoGame ? '1.95em' : '2.95em')};

    p {
      font-size: 2rem;
      text-align: center;
      line-height: 1.5em;
      font-weight: 600;
    }
  }
`;

const NextLevel = styled.div`
  background-color: var(--cx-beige);
  align-items: center;
  color: var(--cx-dark-green);
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  height: ${({ isDemoGame }) => isDemoGame ? '4.9em' : '4.167em'};
  line-height: 1.208em;
  justify-content: center;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

const Icon = styled.img`
  font-size: 1rem;
  margin-top: 1.8em;
  margin-left: 50%;
  max-width: ${({ maxWidth }) => maxWidth};
  transform: translateX(-50%);
  width: 100%;
`;

const CloseModal = styled.button`
  background-color: var(--cx-dark-green);
  width: 2.875rem;
  height: 2.875rem;
  cursor: pointer;
  border: none;
  outline-color: var(--cx-dark-green);
  position: absolute;
  top: 0;
  right: 0;
`;

const WantToSubmitAgain = styled.p`
  text-align: center;
  padding: 1.3rem 1.1rem;
  color: #fff;
  background-color: var(--cx-dark-green);
  font-style: italic;
  font-weight: 500;
`;

export default LevelCompleted;
