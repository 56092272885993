import React from 'react';
import styled from 'styled-components';
import breakText from 'utils/breakText.js';

const Objective = ({ objective }) => (
  <Wrapper>
    <h3>Objective</h3>
    <p dangerouslySetInnerHTML={{ __html: objective }} />
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: rgba(239, 237, 228, 0.3);
  padding-top: 6em;

  > h3 {
    color: var(--cx-dark-green);
    font-size: 2rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0.625em;
  }

  > p {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.333em;
    margin: 0 auto;
    max-width: 42.792em;
  }
`;

export default Objective;
