import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import campaignXLogo from 'images/Shared/Footer/campaign_x_logo.svg';
import agencyXLogo from 'images/Shared/Footer/agency_x_logo.svg';
import dynamicEventsLogo from 'images/Shared/Footer/dynamic_events_logo.svg';
import pardueLogo from 'images/Shared/Footer/pardue_logo.svg';
import emailIcon from 'images/Shared/Footer/email_icon.svg';
import mobileIcon from 'images/Shared/Footer/mobile_icon.svg';
import facebookIcon from 'images/Shared/Footer/facebook_icon.svg';
import linkedInIcon from 'images/Shared/Footer/linkedin_icon.svg';
import twitterIcon from 'images/Shared/Footer/twitter_icon.svg';
import instagramIcon from 'images/Shared/Footer/instagram_icon.svg';

const Footer = ({
  isDemoGame,
  netlifyIdentity,
  toggleContactUsModal,
  toggleScoreboard,
  user,
  viewportWidth
}) => (
  <Wrapper>
    <div>
      <div>
        <p>Want to know more?</p>
        <motion.button
          type="button"
          whileTap={{
            scale: 0.9
          }}
          onClick={(e) => {
            e.preventDefault();
            toggleContactUsModal();
          }}>
          Contact Us
        </motion.button>
      </div>
      {viewportWidth >= 1100 && (
        <div>
          <p>Need some support? </p>
          <p>
            email us at{' '}
            <a href="mailto:support@campaign-x.com" target="__blank">
              support@campaign-x.com
            </a>
          </p>
        </div>
      )}
    </div>
    <FooterLinks>
      <Contacts>
        <h4>Contacts</h4>
        <div>
          <img src={emailIcon} alt="email icon" />
          <p>
            <a style={{ color: '#fff' }} href="mailto:hello@campaign-x.com" target="__blank">
              hello@campaign-x.com
            </a>
          </p>
        </div>
        <div>
          <img src={mobileIcon} alt="mobile icon" />
          <p>01 6706122</p>
        </div>
      </Contacts>
      <div>
        <h4>Sitemap</h4>
        <Link to="/">Home</Link>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            netlifyIdentity.open();
          }}>
          Log In
        </a>
        {user && (
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              toggleScoreboard();
            }}>
            Scoreboard
          </a>
        )}
      </div>
      <div>
        <h4>Support</h4>
        <Link to="/rules/">Rules</Link>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            toggleContactUsModal();
          }}>
          Contact Us
        </a>
        <a href="https://www.agencyx.ie/privacy-policy/" target="__blank">
          Privacy Policy
        </a>
      </div>
      <div>
        <h4>Find Us On</h4>
        <div>
          <a href="https://www.facebook.com/AgencyXdublin/">
            <img src={facebookIcon} alt="facebook logo" />
          </a>
          <a href="https://www.linkedin.com/company/agencyx-dublin/">
            <img src={linkedInIcon} alt="linkedin logo" />
          </a>
          {/* <a href="https://twitter.com/AgencyX_Dublin/">
            <img src={twitterIcon} alt="twitter logo" />
          </a>
          <a href="https://www.instagram.com/agency_x_dublin/">
            <img src={instagramIcon} alt="instagram logo" />
          </a> */}
        </div>
      </div>
    </FooterLinks>
    <BroughtToYouBy isDemoGame={isDemoGame}>
      <div>
        <CampaignXLogo src={campaignXLogo} alt="campaign X logo" isDemoGame={isDemoGame} />
        <p>
          is brought to you by&nbsp;
          {!isDemoGame && (
            <>
              {' '}
              <a href="https://www.agencyx.ie/" target="__blank">
                Agency X
              </a>
              <br />
              in conjunction with&nbsp;
              <a href="https://www.dynamicevents.ie/" target="__blank">
                Dynamic Events
              </a>{' '}
              &{' '}
              <a href="https://www.pardueassociates.com/" target="__blank">
                Pardue
              </a>
            </>
          )}
        </p>
        {isDemoGame && (
          <a href="https://www.agencyx.ie/" target="__blank">
            <AgencyXLogo src={agencyXLogo} alt="agency X logo " isDemoGame={isDemoGame} />
          </a>
        )}
      </div>
      {!isDemoGame && (
        <>
          <div>
            <a href="https://www.agencyx.ie/" target="__blank">
              <AgencyXLogo src={agencyXLogo} alt="agency X logo " />
            </a>
            <a href="https://www.dynamicevents.ie/" target="__blank">
              <DynamicEventsLogo src={dynamicEventsLogo} alt="dynamic events logo" />
            </a>
            <a href="https://www.pardueassociates.com/" target="__blank">
              <PardueLogo src={pardueLogo} alt="pardue logo" />
            </a>
          </div>
        </>
      )}
    </BroughtToYouBy>
    <SmallPrint>
      © {new Date().getFullYear()} Campaign X. All rights reserved. Designed and developed by{' '}
      <a style={{ color: '#fff' }} href="https://www.agencyx.ie" target="__blank">
        Agency X
      </a>
      .
    </SmallPrint>
  </Wrapper>
);

const Wrapper = styled.footer`
  background-color: rgba(0, 0, 0, 0.9);
  padding: 5.625rem 0 1.75rem;
  position: relative;

  @media screen and (min-width: 31.25rem) {
    padding-top: 7.1rem;
  }

  @media screen and (min-width: 48rem) {
    padding-top: 8.4rem;
  }

  h4 {
    color: #fff;
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    margin-left: 50%;
    max-width: 76.25em;
    position: absolute;
    top: -2.25em;
    transform: translateX(-50%);
    width: 100%;

    @media screen and (min-width: 31.25rem) {
      top: -3.75em;
    }

    > div {
      align-items: center;
      background-color: var(--cx-dark-green);
      color: #fff;
      display: flex;
      max-width: 36.25em;
      justify-content: space-between;
      width: 100%;

      &:nth-of-type(1) {
        height: 4.5em;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        padding: 0 2.666666666666667%;
        display: flex;
        justify-content: center;

        @media screen and (min-width: 31.25rem) {
          height: 7.5em;
        }

        @media screen and (min-width: 36.25rem) {
          padding: 0 40px;
          justify-content: space-between;
        }

        @media screen and (min-width: 68.75rem) {
          left: unset;
          height: 7.5em;
          margin-left: 1.25rem;
          margin-right: 0.63rem;
          transform: unset;
          padding: 0 2rem;
        }

        button {
          background-color: #efede4;
          border-radius: 0.125em;
          border: none;
          color: #000;
          cursor: pointer;
          font-family: proxima-nova, sans-serif;
          font-size: 1rem;
          font-weight: 700;
          height: 2.875em;
          line-height: 1.188em;
          margin-left: 1rem;
          outline: none;
          padding-top: 0.188rem;
          text-transform: uppercase;
          width: 10.375em;

          @media screen and (min-width: 580px) {
            margin-left: unset;
          }
        }
      }

      &:nth-of-type(2) {
        height: 7.5em;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.63rem;
        margin-right: 1.25rem;
      }

      p {
        font-weight: 600;
      }

      p:nth-of-type(1) {
        font-size: 1.5rem;
        text-align: center;

        @media screen and (min-width: 31.25rem) {
          font-size: 2rem;
          text-align: left;
        }
      }

      p:nth-of-type(2) {
        font-size: 1.5rem;
        margin-top: 0.11em;

        a {
          color: #fff;
        }
      }
    }
  }
`;

const FooterLinks = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 73.75rem;
  width: 100%;

  @media screen and (min-width: 68.75rem) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;

    @media screen and (min-width: 68.75rem) {
      align-items: flex-start;
    }
  }

  > div:nth-child(2),
  > div:nth-child(3) {
    margin-bottom: 3.5rem;

    h4 {
      margin-bottom: 0.8em;
    }

    a {
      color: #fff;
      display: block;
      font-size: 1.125rem;
      line-height: 1.778em;
      text-decoration: none;
    }

    @media screen and (min-width: 68.75rem) {
      flex-basis: 15.063rem;
    }
  }

  > div:nth-child(4) {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 68.75rem) {
      align-items: flex-start;
      flex-basis: 13.75rem;
      margin-right: 1rem;
      width: 100%;
    }

    > div a:nth-of-type(1) {
      margin-right: 1rem;
    }

    h4 {
      margin-bottom: 0.8em;
    }
  }
`;

const Contacts = styled.div`
  flex-shrink: 1;
  margin-bottom: 3.5rem;

  h4 {
    margin-bottom: 0.75em;
    margin-top: -0.025em;
  }

  > div {
    align-items: center;
    display: flex;

    &:last-of-type {
      align-items: flex-start;
      align-self: flex-start;

      p {
        margin-top: 0.125em;
        padding-right: 1em;

        @media screen and (min-width: 25rem) {
          padding-right: 0;
        }
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 0.938em;
    }

    img {
      margin-right: 1.375em;
      width: 1.625em;
    }

    p {
      color: #fff;
      font-size: 1.125rem;

      @media screen and (min-width: 68.75rem) {
        width: 17.333em;
      }
    }
  }
`;

const BroughtToYouBy = styled.div`
  margin: ${({ isDemoGame }) => (isDemoGame ? '4.5rem auto' : '4.5rem auto 0')};

  @media screen and (min-width: 68.75rem) {
    margin-top: 2rem;
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;

    p,
    & a {
      color: #fff;
    }

    p {
      font-size: 1.125rem;
      line-height: 1.4em;
      margin: ${({ isDemoGame }) => (isDemoGame ? '2rem 2.66%' : '2rem 2.66% 3rem')};
      text-align: center;
    }

    &:nth-of-type(2) {
      a img {
        margin-bottom: 3rem;
      }
    }

    @media screen and (min-width: 68.75rem) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 58.563rem;
      width: 100%;

      p {
        text-align: left;
        margin: unset;
      }

      &:nth-of-type(2) {
        margin-top: 3.875rem;
      }
    }
  }
`;

const AgencyXLogo = styled.img`
  width: 15rem;

  @media screen and (min-width: 68.75rem) {
    width: ${({ isDemoGame }) => (isDemoGame ? '17.313rem' : '11.875rem')};
  }
`;

const DynamicEventsLogo = styled.img`
  width: 13.25rem;

  @media screen and (min-width: 68.75rem) {
    width: 9rem;
  }
`;

const PardueLogo = styled.img`
  width: 8.5rem;

  @media screen and (min-width: 68.75rem) {
    width: 7.313rem;
  }
`;

const CampaignXLogo = styled.img`
  width: 17.9rem;

  @media screen and (min-width: 68.75rem) {
    width: ${({ isDemoGame }) => (isDemoGame ? '21.063rem' : '28.75rem')};
  }
`;

const SmallPrint = styled.p`
  color: #fff;
  font-size: 0.875rem;
  margin-top: 2.25em;
  padding: 0 1em;
  text-align: center;
  width: 100%;
`;

export default Footer;
