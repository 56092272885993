import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { motion } from 'framer-motion';
import { PlayerPics } from 'components';
import { StaticQuery, graphql } from 'gatsby';

const HomePageAndRulesHero = ({ initialClientRender, h1Text, h3Text, scrollToAnchor, pathname }) => (
  <StaticQuery
    query={graphql`
      {
        backgroundImg: file(relativePath: { regex: "/Home/dark_green_background/" }) {
          childImageSharp {
            fluid(maxWidth: 1440, maxHeight: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={({ backgroundImg }) => (
      <Wrapper>
        <BackgroundImg fluid={backgroundImg.childImageSharp.fluid} alt="dark green background" />
        <PlayerPicsAndHeroTextWrapper>
          <PlayerPics initialClientRender={initialClientRender} />
          <HeroTextWrapper pathname={pathname}>
            <H3
              initial={
                initialClientRender
                  ? {
                      x: -250,
                      opacity: 0
                    }
                  : false
              }
              animate={{
                x: 0,
                opacity: 1
              }}
              transition={{
                delay: 0.7
              }}
              dangerouslySetInnerHTML={{ __html: h3Text }}
            />
            <H1
              initial={
                initialClientRender
                  ? {
                      x: 250,
                      opacity: 0
                    }
                  : false
              }
              animate={{
                x: 0,
                opacity: 1
              }}
              transition={{
                delay: 0.7
              }}
              dangerouslySetInnerHTML={{ __html: h1Text }}
            />
            <FindOutMore
              initial={
                initialClientRender
                  ? {
                      y: 50,
                      opacity: 0
                    }
                  : false
              }
              animate={{
                y: 0,
                opacity: 1
              }}
              transition={{
                duration: 0.65,
                delay: 1.15
              }}
              onClick={() => scrollToAnchor('find-out-more', -80)}>
              Find Out More
            </FindOutMore>
          </HeroTextWrapper>
        </PlayerPicsAndHeroTextWrapper>
      </Wrapper>
    )}
  />
);

const Wrapper = styled.section`
  /* height: 41.5em; */
  height: 100vh;
  position: relative;

  h1,
  h3,
  button {
    color: #fff;
  }

  @media screen and (min-width: 768px) {
    max-height: 56.25em;
    height: 100vh;
  }
`;

const BackgroundImg = styled(Img)`
  height: 100%;
`;

const PlayerPicsAndHeroTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 3.75em;
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  max-height: 900px;

  @media screen and (min-width: 768px) {
    top: 5em;
  }
`;

const HeroTextWrapper = styled.div`
  bottom: 0.125rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 540px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1220px;
  padding-left: 0.625rem;
  position: relative;
  width: 100%;
  z-index: 1;

  @media screen and (min-width: 550px) {
    padding-left: 1.25rem;
  }

  @media screen and (min-width: 768px) {
    bottom: 0.825rem;
  }
`;

const H3 = styled(motion.h3)`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.125em;

  @media screen and (min-width: 360px) {
    font-size: 1.125rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.725rem;
  }

  @media screen and (min-width: 65em) {
    font-size: 2rem;
  }
`;

const H1 = styled(motion.h1)`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.222em;
  text-transform: capitalize;
  max-width: 288px;
  margin: 0.325em 0 0.25em;

  @media screen and (min-width: 360px) {
    font-size: 2.625rem;
    margin: 0.175em 0;
  }

  @media screen and (min-width: 550px) {
    font-size: 2.625rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 3.8rem;
    max-width: unset;
    margin: 0.2em 0;
  }

  @media screen and (min-width: 65em) {
    font-size: 4.5rem;
  }
`;

const FindOutMore = styled(motion.button)`
  background-color: var(--cx-dark-green);
  border-radius: 0.125em;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  height: 2.875em;
  line-height: 1.214em;
  text-transform: uppercase;
  width: 10.375em;
  margin-top: 0.25em;
  outline: none;

  @media screen and (min-width: 360px) {
    margin-top: 0.75em;
  }

  @media screen and (min-width: 768px) {
    line-height: 1.188em;
  }

  &:hover {
    background-color: #007737;
    transition: background-color 150ms ease-in-out;
  }

  &:not(:hover) {
    background-color: var(--cx-dark-green);
    transition: background-color 150ms ease-in-out;
  }
`;

export default HomePageAndRulesHero;
