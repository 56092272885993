import React from 'react';
import styled, { css } from 'styled-components';

const Challenge = ({ challenge, reduceToolsFontSize, duration, tools, isDemoGame }) => (
  <Wrapper isDemoGame={isDemoGame}>
    <h3>Challenge</h3>
    <h2
      dangerouslySetInnerHTML={{
        __html: challenge.heading
      }}
    />
    <p
      dangerouslySetInnerHTML={{
        __html: challenge.detail
      }}
    />
    {!isDemoGame && (
      <div>
        <Duration>
          <p>
            Duration: <span dangerouslySetInnerHTML={{ __html: duration.time }} />
          </p>
          {duration.detail && <p dangerouslySetInnerHTML={{ __html: duration.detail }} />}
        </Duration>
        <Tools toolsLink={tools.link} reduceToolsFontSize={reduceToolsFontSize}>
          <p>
            Tools: <span dangerouslySetInnerHTML={{ __html: tools.list }} />
          </p>
          {tools.link && (
            /* Checks if there are unique docs for this game's selected level, if not the link falls back to the global pool of docs in 'tools' */
            <a
              href={tools.link.publicURL ? `${tools.link.publicURL}` : `/tools/${tools.link}`}
              download>
              Download
            </a>
          )}
        </Tools>
      </div>
    )}
  </Wrapper>
);

const Wrapper = styled.section`
  background-color: rgba(239, 237, 228, 0.3);
  padding: 5.3rem 9.027777777777777% ${({ isDemoGame }) => isDemoGame ? '1.75em' : '0'};

  h3 {
    color: var(--cx-dark-green);
    font-size: 2rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    margin: 0.3625em auto 0.625em;
  }

  h2 {
    font-size: 2rem;
    /* letter-spacing: 0.1em; */
    text-align: center;
    margin: 0 auto 0.88em;
    max-width: 36.875em;
  }

  > p {
    text-align: center;
    font-size: 1.5rem;
    max-width: 39.167em;
    margin: 0 auto 1.75em;

    a {
      color: #000;
    }
  }

  > div {
    max-width: 73.75rem;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 5.35rem;

    > div {
      background-color: var(--cx-beige);
      color: #000;
      width: 36.25em;
      padding: 2.5em 2.5em 2.375em;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 2rem;
        font-weight: 700;
      }

      span {
        color: var(--cx-dark-green);
      }
    }
  }
`;

const Duration = styled.div`
  margin-right: 0.625rem;
  justify-content: flex-start;

  p:nth-of-type(2) {
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 0.3em;
    text-align: center;
  }
`;

const Tools = styled.div`
  justify-content: ${({ toolsLink }) => (toolsLink ? 'flex-start' : 'center')};
  margin-left: 0.625rem;

  > p {
    text-align: center;

    > span {
      ${({ reduceToolsFontSize }) =>
        reduceToolsFontSize &&
        css`
          font-size: 1.5rem;
          line-height: 1.333em;
          display: inline-block;
        `};
    }
  }

  a {
    align-items: center;
    background-color: #fff;
    border-radius: 0.125em;
    color: #000;
    display: flex;
    font-weight: 700;
    height: 2.875rem;
    justify-content: center;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    top: 0.9em;
    width: 9em;
  }
`;

export default Challenge;
