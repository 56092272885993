import React, { PureComponent } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Video } from 'components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

class LevelHero extends PureComponent {
  render() {
    const { level, title, subTitle, levelPageSummary, levelVideoCode, isDemoGame } = this.props;
    return (
      <StaticQuery
        query={graphql`
          {
            backgroundImg: file(relativePath: { regex: "/Home/dark_green_background/" }) {
              childImageSharp {
                fluid(maxWidth: 1440, maxHeight: 900, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        `}
        render={({ backgroundImg }) => (
          <Wrapper subTitle={subTitle}>
            <BackgroundImg
              fluid={backgroundImg.childImageSharp.fluid}
              alt="dark green background"
            />
            <LevelIndicatorWrapper>
              <LevelIndicator isDemoGame={isDemoGame && level === 1}>
                <p>
                  {isDemoGame && level === 1 ? 'DEMO' : level === 10 ? 10 : `0${level}`}
                  <span>Level</span>
                </p>
              </LevelIndicator>
            </LevelIndicatorWrapper>
            <ContentWrapper>
              <VideoWrapper>
                <Video videoCode={levelVideoCode} />
              </VideoWrapper>
              <h1
                dangerouslySetInnerHTML={{
                  __html: title
                }}
              />
              {subTitle && <h3>{subTitle}</h3>}
              <p
                dangerouslySetInnerHTML={{
                  __html: levelPageSummary
                }}
              />
            </ContentWrapper>
          </Wrapper>
        )}
      />
    );
  }
}

const fadeInVideo = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const VideoWrapper = styled.div`
  max-width: 53.125rem;
  width: 100%;
  opacity: 0;
  animation: ${fadeInVideo} 1000ms ease-in-out forwards;
  border: 0.063rem solid #009245;
`;

const ContentWrapper = styled.div`
  position: absolute;
  left: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  top: 6.313em;
  width: 100%;

  h1,
  h3,
  p,
  button {
    color: #fff;
  }

  h1 {
    font-size: 4.25rem;
    font-weight: 700;
    line-height: 1.222em;
    margin-top: 0.6em;
    margin-bottom: 0.27em;
    text-align: center;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin: -0.15em auto 0.85em;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.333em;
    text-align: center;
    margin-bottom: 1.5em;
    max-width: 72rem;
    padding: 0 1em;
  }

  button {
    margin-bottom: 1em;
  }
`;

const LevelIndicator = styled.div`
  background-color: var(--cx-dark-green);
  width: 6.25em;
  height: 6.25em;
  align-items: center;
  border-radius: 0.125em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  p,
  span {
    font-weight: 700;
  }

  p {
    color: #fff;
    font-size: ${({ isDemoGame }) => isDemoGame ? '1.1rem' : '3rem'};
    height: 1.104em;
    letter-spacing: 0.025em;
    line-height: 0.9em;
    pointer-events: none;

    ${({ isDemoGame }) => isDemoGame && css`
      position: relative;
      bottom: 0.2125em;
    `}
  }

  span {
    bottom: ${({ isDemoGame }) => isDemoGame ? '-0.2em' : '0.75em'};
    color: #000;
    display: block;
    font-size: 1rem;
    letter-spacing: 0.1em;
    pointer-events: none;
    position: relative;
    text-transform: uppercase;
  }
`;

const Wrapper = styled.section`
  height: ${({ subTitle }) => (subTitle ? '59.5rem' : '55rem')};
  position: relative;
`;

const LevelIndicatorWrapper = styled.div`
  left: 1.25rem;
  pointer-events: none;
  position: absolute;
  top: 6.313em;
  width: 100%;

  @media screen and (min-width: 78.75rem) {
    left: 50%;
    max-width: 76.25rem;
    transform: translateX(-50%);
  }
`;

const BackgroundImg = styled(Img)`
  height: 100%;
`;

export default LevelHero;
