import React from 'react';
import styled, { css } from 'styled-components';
import GreenRightArrow from 'images/Home/GreenRightArrow';
import XsAndOs2 from 'images/Home/XsAndOs2';

const clientBenefits = [
  'Full team engagement',
  'Broader ideation than agency alone',
  'Experience backed strategy',
  'Combines creative with commercial acumen',
  'Remote worker friendly',
  'Purpose built for new market conditions',
  'First mover advantage',
  'Fully prepared team for launch',
  'A unique competitive advantage',
  'Post launch feedback structure for continual improvement'
];

const playerBenefits = [
  'Learn new skills',
  'Identify new skills/talents',
  'Use full spectrum of experience',
  'Shape your future success',
  'Build new relationships',
  'Address new challenges',
  'Expand your business perspective',
  'Create worthwhile work',
  'Have fun',
  'Win prizes!'
];

export default ({ viewportWidth, isDemoGame }) => (
  <Wrapper isDemoGame={isDemoGame}>
    <div style={{ position: 'relative', zIndex: 1 }}>
      <h3>{isDemoGame ? 'Campaign X Benefits' : 'Benefits'}</h3>
      <div>
        <ClientBenefits>
          <ul>
            <li>To The Client</li>
            {clientBenefits.map((clientBenefit) => (
              <li key={clientBenefit}>
                <StyledGreenRightArrow />
                <span>{clientBenefit}</span>
                <StyledGreenLeftArrow />
              </li>
            ))}
          </ul>
        </ClientBenefits>
        <PlayerBenefits>
          <ul>
            <li>To The Players (your team)</li>
            {playerBenefits.map((playerBenefit) => (
              <li key={playerBenefit}>
                <StyledGreenRightArrow />
                <span>{playerBenefit}</span>
                <StyledGreenLeftArrow />
              </li>
            ))}
          </ul>
        </PlayerBenefits>
      </div>
      {viewportWidth >= 650 && <StyledXsAndOs2 />}
    </div>
    {!isDemoGame && (
      <p>
        The market conditions have changed for everyone. Remote working is on the rise, face to face
        meetings are in decline. How will we collaborate, create and compete in this new, changing
        environment?
      </p>
    )}
  </Wrapper>
);

const Wrapper = styled.section`
  ${({ isDemoGame }) => isDemoGame && css`
    padding-bottom: 4rem;
  `};

  h3 {
    color: var(--cx-dark-green);
    font-size: 2rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    margin: 3.725em auto 0.82em;
  }

  > div > div {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 36.25em;

    @media screen and (min-width: 65.625em) {
      flex-direction: row;
      justify-content: space-between;
      max-width: unset;
    }
  }

  > p {
    font-size: 1.125em;
    line-height: 1.333em;
    margin: 2.25em auto 2em;

    @media screen and (min-width: 40.625rem) {
      font-size: 1.5rem;
      margin: 3.6em auto 2.8em;
      max-width: 38.833em;
    }
  }
`;

const Benefits = styled.div`
  border: 0.063em solid var(--cx-dark-green);
  border-radius: 0.125em;
  max-width: 36.25em;
  width: 100%;

  ul,
  li {
    text-align: center;
  }

  ul {
    list-style-type: none;
  }

  li {
    background-color: #fff;
    color: #000;
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-of-type {
      background-color: var(--cx-dark-green);
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
      height: 3.083em;
      margin-bottom: 0.9em;
    }

    &:not(:first-of-type) {
      padding: 0.6em 0;

      span {
        display: inline-block;
        max-width: 21em;
      }

      &:hover {
        background-color: rgba(239, 237, 228, 0.3);
        color: var(--cx-dark-green);
        cursor: default;
        font-weight: 700;
        letter-spacing: 0.01em;
        transition: background-color 150ms ease-in-out, color 150ms ease-in-out,
          font-weight 150ms ease-in-out, letter-spacing 150ms ease-in-out;

        svg {
          opacity: 1;
          transition: opacity 150ms ease-in-out;
        }
      }

      &:not(:hover) {
        background-color: #fff;
        color: #000;
        cursor: default;
        font-weight: 600;
        letter-spacing: 0;
        transition: background-color 150ms ease-in-out, color 150ms ease-in-out,
          font-weight 150ms ease-in-out, letter-spacing 150ms ease-in-out;

        svg {
          opacity: 0;
          transition: opacity 150ms ease-in-out;
        }
      }
    }
  }
`;

const ClientBenefits = styled(Benefits)`
  margin-bottom: 1.25em;

  @media screen and (min-width: 65.625rem) {
    margin-bottom: 0;
    margin-right: 0.625em;
  }
`;

const PlayerBenefits = styled(Benefits)`
  @media screen and (min-width: 65.625rem) {
    margin-left: 0.625em;
  }
`;

const StyledGreenRightArrow = styled(GreenRightArrow)`
  margin: 0 1em 0 0;
  width: 1.794em;
`;

const StyledGreenLeftArrow = styled(StyledGreenRightArrow)`
  transform: rotate(180deg);
  margin: 0 0 0 1em;
`;

const StyledXsAndOs2 = styled(XsAndOs2)`
  bottom: -12.75em;
  left: -6.75em;
  position: absolute;
  width: 13.288em;
`;
