import * as React from 'react';

function SvgXsAndOs4(props) {
  return (
    <svg viewBox="0 0 542 580" fill="none" {...props}>
      <path
        d="M391.474 579.918c-43.048 0-78.092-35.044-78.092-78.092 0-43.049 35.044-78.092 78.092-78.092s78.092 35.043 78.092 78.092c0 43.048-35.044 78.092-78.092 78.092zm0-108.155c-16.543 0-30.063 13.519-30.063 30.063 0 16.543 13.52 30.062 30.063 30.062 16.544 0 30.063-13.519 30.063-30.062 0-16.722-13.519-30.063-30.063-30.063zM421.62 112.29c-27.217 0-49.275-22.058-49.275-49.275 0-27.216 22.058-49.274 49.275-49.274 27.216 0 49.274 22.058 49.274 49.275 0 27.216-22.236 49.274-49.274 49.274zm0-68.13c-10.496 0-19.034 8.538-19.034 19.033 0 10.496 8.538 19.034 19.034 19.034 10.495 0 19.033-8.538 19.033-19.034-.178-10.495-8.716-19.033-19.033-19.033zM309.793 51.587c-14.23 0-25.793-11.563-25.793-25.794C284 11.563 295.563 0 309.793 0c14.231 0 25.794 11.563 25.794 25.793 0 14.231-11.563 25.794-25.794 25.794zm0-35.755a9.945 9.945 0 00-9.961 9.961 9.945 9.945 0 009.961 9.962 9.945 9.945 0 009.962-9.962 9.945 9.945 0 00-9.962-9.961zM287.393 442.39c-14.231 0-25.793-11.563-25.793-25.794s11.562-25.793 25.793-25.793 25.794 11.562 25.794 25.793-11.563 25.794-25.794 25.794zm0-35.755a9.945 9.945 0 00-9.961 9.961 9.945 9.945 0 009.961 9.962 9.945 9.945 0 009.962-9.962 9.945 9.945 0 00-9.962-9.961zM144.534 397.611L126.746 380l-19.568 19.39L87.788 380 70 397.611l19.567 19.567L70 436.746l17.789 17.61 19.389-19.389 19.568 19.389 17.788-17.61-19.567-19.568 19.567-19.567zM636.486 92.976L602.51 59l-37.178 37.178L527.976 59 494 92.976l37.356 37.178L494 167.332l33.976 33.977 37.356-37.179 37.178 37.179 33.976-33.977-37.178-37.178 37.178-37.178zM429.808 260.087l-42.61 56.417 50.817 38.542 42.611-56.417-50.818-38.542zM408.4 145.524l-37.842 50.105 57.118 43.323 37.841-50.105-57.117-43.323z"
        fill="#C1BEAC"
        fillOpacity={0.2}
      />
      <path
        d="M360.88 207.794l-62.009-47.68-42.237 56.695 62.009 47.68-46.18 62.301 56.669 43.574 46.179-62.301 42.237-56.695-56.668-43.574z"
        fill="#009245"
        fillOpacity={0.2}
      />
      <path
        d="M806 353.532l-53.318-83.159-91.128 58.403-58.371-91.004-83.113 53.173 58.545 91.178-91.128 58.403 53.318 83.159 90.954-58.403 58.545 91.004 82.939-53.173-58.371-91.178L806 353.532zM40.944 445.288L29.7 437l-9.044 12.188-12.345-9.019L0 451.26l12.222 9.142-9.166 12.188L14.3 481l9.167-12.31 12.222 9.141L44 466.618l-12.222-9.02 9.166-12.31zM274.693 486.337l-19.567-30.419-33.265 21.347L200.337 444l-30.419 19.567 21.525 33.265L158 518.178l19.567 30.419 33.265-21.346 21.524 33.442 30.419-19.567-21.525-33.443 33.443-21.346z"
        fill="#C1BEAC"
        fillOpacity={0.2}
      />
    </svg>
  );
}

export default SvgXsAndOs4;
